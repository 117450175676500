import { animationSizes } from '../../animation/animationSizes';

// Calc to shift camera left so that scene cutoff is not visible at end of anim
export const cameraShift = () => {
    const sizes = animationSizes();
    if (!sizes) return 0;

    return Math.max(sizes.animation.extraWidth - 350, 0);
};

export const P1_NUMS = {
    WALK_DIST_1: 690,
    WALK_DUR_1: 104,
};

export const P2_NUMS = {
    WALK_DIST_1: 900,
    WALK_DUR_1: 90,
};

export const P3_NUMS = {
    WALK_DIST_1: 500,
    WALK_DUR_1: 50,
    
    WALK_DIST_2: 750,
    WALK_DUR_2: 75,
};

export const SCENE3B_DUR = 515;
