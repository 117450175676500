import { loadingBeginning, loadProgress } from '../intro-page/loading';

loadingBeginning();

const fire = require('../../assets/audio/Scene1/fire_2.mp3');
const waterfall = require('../../assets/audio/Scene1/waterfall_3.mp3');
const scene1Background = require('../../assets/audio/Scene1/Hawaiki_RC_14_01_2020.mp3');
const moana = require('../../assets/audio/Scene1/Moana.mp3');
const taiAo = require('../../assets/audio/Scene1/TaiAo.mp3');
const carving = require('../../assets/audio/Scene1/Whakairo.mp3');
const scene2Background = require('../../assets/audio/Scene2/Moananui_RC_24_2_2020.mp3');
const scene3bBackground = require('../../assets/audio/Scene3/Hokianga_RC_24_2_2020.mp3');
const creditsBackground = require('../../assets/audio/Credits/Various_Artists-12_Karanga_Hokianga.mp3');
const walking = require('../../assets/audio/Scene3/Walking.mp3');
const waterfall1 = require('../../assets/audio/Scene3/waterfall_1.mp3');
const manu = require('../../assets/audio/Scene3/Manu_SFX.mp3');
const thunder = [require('../../assets/audio/Scene2/Thunder_SFX_32k.webm'), require('../../assets/audio/Scene2/Thunder_SFX.mp3')];

import * as soundNames from './soundNames';

const createSound = function (files, volume = 1, loop = true) {
    const filesList = Array.isArray(files) ? files : [files];
    return new Howl({
        src: filesList,
        onloaderror: (e, f) => { console.dir(e); console.dir(f) },
        onplayerror: (e, f) => { console.dir(e); console.dir(f) },
        onload: () => {
            loadProgress();
        },
        preload: true,
        volume,
        loop,
        autoplay: false
    });
}

const FX_VOLUME = .2;
const MUSIC_VOLUME = .2;

const fxSounds = {
    [soundNames.fire]: createSound(fire, FX_VOLUME),
    [soundNames.waterfall]: createSound(waterfall, FX_VOLUME * .7),
    [soundNames.moana]: createSound(moana, FX_VOLUME),
    [soundNames.taiAo]: createSound(taiAo, FX_VOLUME),
    [soundNames.carving]: createSound(carving, FX_VOLUME),
    [soundNames.walking]: createSound(walking, FX_VOLUME),
    [soundNames.waterfall1]: createSound(waterfall1, FX_VOLUME),
    [soundNames.manu]: createSound(manu, FX_VOLUME),
    [soundNames.thunder]: createSound(thunder, .3),
}

const bgSounds = {
    [soundNames.scene1Background]: createSound(scene1Background, MUSIC_VOLUME),
    [soundNames.scene2Background]: createSound(scene2Background, MUSIC_VOLUME),
    [soundNames.scene3bBackground]: createSound(scene3bBackground, MUSIC_VOLUME),
    [soundNames.creditsBackground]: createSound(creditsBackground, MUSIC_VOLUME * 1.5),
};

const voSounds = {};
const voSoundFileNames = {
    // == Scene 1 ==
    // S1P1
    "S1/ID1 P1 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID1_P1_TRM.mp3'),
    "S1/ID1 P1 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID1_P1_ENG.mp3'),
    "S1/ID2 P1 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID2_P1_TRM.mp3'),
    "S1/ID2 P1 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID2_P1_ENG.mp3'),
    "S1/ID3 P1 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID3_P1_TRM.mp3'),
    "S1/ID3 P1 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID3_P1_ENG.mp3'),

    // S1P2
    "S1/ID4 P2 TRM": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID4_P2_TRM.mp3'),
    "S1/ID4 P2 ENG": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID4_P2_ENG.mp3'),
    "S1/ID5 P2 TRM": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID5_P2_TRM.mp3'),
    "S1/ID5 P2 ENG": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID5_P2_ENG.mp3'),
    "S1/ID6 P2 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID6_P2_TRM.mp3'),
    "S1/ID6 P2 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID6_P2_ENG.mp3'),
    "S1/ID7 P2 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID7_P2_TRM.m4a'),
    "S1/ID7 P2 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID7_P2_ENG.m4a'),

    // S1P3
    "S1/ID8 P3 TRM": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID8_P3_TRM.mp3'),
    "S1/ID8 P3 ENG": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID8_P3_ENG.mp3'),
    "S1/ID9 P3 TRM": require('../../assets/audio/Scene1/voiceovers/KUPE/ID9_P3_TRM.mp3'),
    "S1/ID9 P3 ENG": require('../../assets/audio/Scene1/voiceovers/KUPE/ID9_P3_ENG.mp3'),

    // S1P4
    "S1/ID10 P4 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID10_P4_TRM.mp3'),
    "S1/ID10 P4 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID10_P4_ENG.mp3'),
    "S1/ID11 P4 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID11_P4_TRM.mp3'),
    "S1/ID11 P4 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID11_P4_ENG.mp3'),
    "S1/ID12 P4 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID12_P4_TRM.mp3'),
    "S1/ID12 P4 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID12_P4_ENG.mp3'),

    // S1P5
    "S1/ID13 P5 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID13_P5_TRM.mp3'),
    "S1/ID13 P5 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID13_P5_ENG.mp3'),
    "S1/ID14 P5 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID14_P5_TRM.mp3'),
    "S1/ID14 P5 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID14_P5_ENG.mp3'),
    "S1/ID15 P5 TRM": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID15_P5_TRM.mp3'),
    "S1/ID15 P5 ENG": require('../../assets/audio/Scene1/voiceovers/NUKUTAWHITI/ID15_P5_ENG.mp3'),

    // S1P6
    "S1/ID16 P6 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID16_P6_TRM.mp3'),
    "S1/ID16 P6 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID16_P6_ENG.mp3'),
    "S1/ID17 P6 TRM": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID17_P6_TRM.mp3'),
    "S1/ID17 P6 ENG": require('../../assets/audio/Scene1/voiceovers/NARRATOR/ID17_P6_ENG.mp3'),


    // == Scene 2 ==
    // S2P1
    "S2/ID18 P1 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID18_P1_TRM.mp3'),
    "S2/ID18 P1 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID18_P1_ENG.mp3'),
    "S2/ID19 P1 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID19_P1_TRM.mp3'),
    "S2/ID19 P1 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID19_P1_ENG.mp3'),
    "S2/ID20 P1 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID20_P1_TRM.mp3'),
    "S2/ID20 P1 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID20_P1_ENG.mp3'),

    // S2P2
    "S2/ID21 P2 TRM": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID21_P2_TRM-ONLY.mp3'), // TRM only
    "S2/ID22 P2 TRM": require('../../assets/audio/Scene2/voiceovers/KURI/ID22_P2_TRM.mp3'),
    "S2/ID22 P2 ENG": require('../../assets/audio/Scene2/voiceovers/KURI/ID22_P2_ENG.mp3'),
    "S2/ID23 P2 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID23_P2_TRM.mp3'),
    "S2/ID23 P2 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID23_P2_ENG.mp3'),

    // S2P3
    "S2/ID24 P3 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID24_P3_TRM.mp3'),
    "S2/ID24 P3 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID24_P3_ENG.mp3'),
    "S2/ID25 P3 TRM": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID25_P3a_TRM.mp3'),
    "S2/ID25 P3 ENG": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID25_P3a_ENG.mp3'),
    "S2/ID26 P3 TRM": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID26_P3a_TRM.mp3'),
    "S2/ID26 P3 ENG": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID26_P3a_ENG.mp3'),
    "S2/ID27 P3 TRM": require('../../assets/audio/Scene2/voiceovers/KURI/ID27_P3a_TRM.mp3'),
    "S2/ID27 P3 ENG": require('../../assets/audio/Scene2/voiceovers/KURI/ID27_P3a_ENG.mp3'),

    // S2P4
    "S2/ID28 P4 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID28_P4_TRM.mp3'),
    "S2/ID28 P4 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID28_P4_ENG.mp3'),
    "S2/ID29 P4 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID29_P4_TRM.mp3'),
    "S2/ID29 P4 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID29_P4_ENG.mp3'),
    "S2/ID30 P4 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID30_P4_TRM.mp3'),
    "S2/ID30 P4 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID30_P4_ENG.mp3'),
    "S2/ID31 P4 TRM": require('../../assets/audio/Scene2/voiceovers/KURI/ID31_P4_TRM.mp3'),
    "S2/ID31 P4 ENG": require('../../assets/audio/Scene2/voiceovers/KURI/ID31_P4_ENG.mp3'),

    "S2/ID32 P4 TRM": require('../../assets/audio/Scene2/voiceovers/PUHIMOANAARIKI/ID32_P4_TRM.mp3'),
    "S2/ID32 P4 ENG": require('../../assets/audio/Scene2/voiceovers/PUHIMOANAARIKI/ID32_P4_ENG.mp3'),

    // S2P5
    "S2/ID33 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID33_P5_TRM.mp3'),
    "S2/ID33 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID33_P5_ENG.mp3'),
    "S2/ID34 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID34_P5_TRM.mp3'),
    "S2/ID34 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID34_P5_ENG.mp3'),
    "S2/ID35 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID35_P5_TRM.mp3'),
    "S2/ID35 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID35_P5_ENG.mp3'),
    "S2/ID36 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID36_P5_TRM.mp3'),
    "S2/ID36 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID36_P5_ENG.mp3'),
    "S2/ID37 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID37_P5_TRM.mp3'),
    "S2/ID37 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NUKUTAWHITI/ID37_P5_ENG.mp3'),
    "S2/ID38 P5 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID38_P5_TRM.mp3'),
    "S2/ID38 P5 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID38_P5_ENG.mp3'),

    // S2P6
    "S2/ID39 P6 TRM": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID39_P6_TRM.mp3'),
    "S2/ID39 P6 ENG": require('../../assets/audio/Scene2/voiceovers/NARRATOR/ID39_P6_ENG.mp3'),
    "S2/ID40_P6_Waiata_TRM": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID40_P6_Waiata_TRM.mp3'),
    "S2/ID40_P6_Waiata_ENG": require('../../assets/audio/Scene2/voiceovers/CHILDREN/ID40_P6_Waiata_ENG.mp3'),

    // == Scene 3a ==
    // S3aP1
    "S3/ID41 P1 TRM": require('../../assets/audio/Scene3/voiceovers/NUKUTAWHITI/ID41_P1_TRM.mp3'),
    "S3/ID41 P1 ENG": require('../../assets/audio/Scene3/voiceovers/NUKUTAWHITI/ID41_P1_ENG.mp3'),

    // S3aP2
    "S3/ID42 P2 TRM": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID42_P2_TRM.m4a'),
    "S3/ID42 P2 ENG": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID42_P2_ENG.m4a'),
    "S3/ID43 P2 TRM": require('../../assets/audio/Scene3/voiceovers/NUKUTAWHITI/ID43_P2_TRM.mp3'),
    "S3/ID43 P2 ENG": require('../../assets/audio/Scene3/voiceovers/NUKUTAWHITI/ID43_P2_ENG.mp3'),

    // S3aP3
    "S3/ID44 P3 TRM": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID44_P3_TRM.m4a'),
    "S3/ID44 P3 ENG": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID44_P3_ENG.m4a'),
    "S3/ID45 P3 TRM": require('../../assets/audio/Scene3/voiceovers/NUKUTAWHITI/ID45_P3_TRM-ONLY.mp3'),

    // == Scene 3b ==
    // S3bP1
    "S3/ID47 P1 TRM": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID47_P1_TRM.m4a'),
    "S3/ID47 P1 ENG": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID47_P1_ENG.m4a'),
    // S3bP2
    "S3/ID48 P2 TRM": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID48_P2_TRM.m4a'),
    "S3/ID48 P2 ENG": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID48_P2_ENG.m4a'),

    // S3bP3
    "S3/ID49 P3 TRM": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID49_P3_TRM.m4a'),
    "S3/ID49 P3 ENG": require('../../assets/audio/Scene3/voiceovers/NARRATOR/S3_ID49_P3_ENG.m4a'),
  
};

let fxMuted = false, bgMuted = false;
let soundIds = {};

const getSound = (soundName) => {
    const sound = fxSounds[soundName]
        || bgSounds[soundName]
        || (voSounds[soundName]);
    let soundId = soundIds[soundName];
    return { sound, soundId };
}

const toggleSoundPlaying = (soundName, stop_if_playing = false) => {
    const { soundId, sound } = getSound(soundName);
    if (!sound) {
        console.log(`sound ${sound} not found.`);
        return;
    }
    if (!soundId) {
        console.log('sound playing: ' + soundName);
        soundIds[soundName] = sound.play();
    } else {
        if (sound.playing(soundId)) {
            //sound.fade(sound.volume(soundId), 0, 1000, soundId);
            // console.log('pausing' + soundId)
            console.log('sound pausing: ' + soundName)
            if (stop_if_playing) sound.stop(soundId);
            else sound.pause(soundId);
        } else {
            console.log('sound playing: ' + soundName)
            sound.play(soundId);
            //sound.fade(0, sound.volume(soundId), 1000, soundId);
        }
    }
}

const setSoundPosition = (soundName, position) => {
    const { soundId, sound } = getSound(soundName);
    if (sound && soundId) {
        sound.seek(position, soundId);
    }
}

const getVoiceOverFile = (voSound, langCode) => {
    const soundName = voSound[langCode];
    return voSoundFileNames[soundName];
};

const playVoiceOver = (voSound, voiceBtn) => {
    const langCode = window.lang;
    const soundName = voSound[langCode];
    const soundFileInfo = getVoiceOverFile(voSound, langCode);
    const soundFile = Array.isArray(soundFileInfo) ? soundFileInfo[0] : soundFileInfo;
    const vol = Array.isArray(soundFileInfo) ? soundFileInfo[1] : 1.0;
    voSounds[soundName] = voSounds[soundName] || createSound(soundFile, vol, false);
    const sound = voSounds[soundName];
    const PLAYING_CLASS = 'playing';
    const LOADING_CLASS = 'sound-loading';

    let loading = true;

    const onEnd = () => {
        loading = false;
        voiceBtn[0].classList.remove(PLAYING_CLASS);
        voiceBtn[0].classList.remove(LOADING_CLASS)
    }

    // stop currently playing VOs
    for (const soundName2 in voSounds) {
        const sound2 = voSounds[soundName2];
        if (sound2.playing()) {
            console.log('VO STOP', soundName2);
            sound2.stop();
            if (soundName == soundName2) {
                onEnd();
                return;
            }
        }
    }

    sound.once('play', () => {
        loading = false;
        voiceBtn[0].classList.remove(LOADING_CLASS)
        voiceBtn[0].classList.add(PLAYING_CLASS)
    });
    sound.once('stop', onEnd);
    sound.once('end', onEnd);

    window.setTimeout(() => {
        if (loading) voiceBtn[0].classList.add(LOADING_CLASS);
    }, 400);

    toggleSoundPlaying(soundName);
}


const toggleBgMusicVolume = () => {
    Object.keys(bgSounds).forEach(x => bgSounds[x].mute(!bgMuted));
    bgMuted = !bgMuted;
}

const toggleFxVolume = () => {
    Object.keys(fxSounds).forEach(x => fxSounds[x].mute(!fxMuted));
    fxMuted = !fxMuted;
}


const triggerInitialAudio = () => {
    toggleSoundPlaying(soundNames.scene1Background);
    toggleSoundPlaying(soundNames.waterfall);
    toggleSoundPlaying(soundNames.taiAo);
    fxSounds[soundNames.carving].pause();
}

export {
    toggleSoundPlaying, toggleBgMusicVolume, toggleFxVolume,
    triggerInitialAudio,
    playVoiceOver, getVoiceOverFile,
    setSoundPosition,
    soundNames
}
