import { rockTaniwhaTl } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade, registerThoughtBubble } from '../../../subtitles/subtitleEffects';

registerSetSubtitleWithFade();
registerThoughtBubble();

const $scene = document.querySelector('.scene');

const BACKGROUND_SEA_POSITION = 5570;
const TANIWHA_ANIM_REPEATS = 61;
const TANIWHA_ANIM_DURATION = 2;
const ROCK_DIST = 600;
const SPEECH_TL1_PLAYBTN = '#speech-slot-tl1 .voiceover-btn';


const panel4Tl_A = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    // Camera zoom out & move right a bit
    .to($scene, { scale: 2, x: '-=300', duration: 5, ease: ExpoScaleEase.config(1, 2)})

    // Narator
    .setSubtitleWithFade('#bottom-narration-slot-1', { subTitle: 28 - 1, left: 10, x: BACKGROUND_SEA_POSITION - 370, y: 0, width: 505 })
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 29 - 1, left: 10, bottom: '0.5%', x: BACKGROUND_SEA_POSITION - 60, y: -20, width: 683 })

    // Put rock to the right of boat
    .set('#rock-with-waves', { x: BACKGROUND_SEA_POSITION + ROCK_DIST - 50, y: 528 })
    .to('#rock-with-waves', { opacity: 1 })

    // Move rock to net
    .to('#rock-with-waves', { x: `-=${ROCK_DIST}`, duration: 5 }, '<')

    // Narator: "Suddenly, however,"
    .setSubtitleWithFade('#top-narration-slot-3', { subTitle: 30 - 1, x: BACKGROUND_SEA_POSITION - 350, y: 20, width: 500 })

    // Kuri
    .to('#thought-bubble-container .thought-bubble', { width: 402, height: 103 })
    .thoughtBubble('#thought-bubble-container', { subTitle: 31 - 1, x: 5610, y: 130, width: 381, height: 131 })

    // Puhimoanaariki (Orange Taniwha)
    .set(SPEECH_TL1_PLAYBTN, { x: 2, y: -3 })
    .setSubtitleWithFade('#speech-slot-tl1', { subTitle: 32 - 1, x: BACKGROUND_SEA_POSITION -45, y: 560, scale: 1.2 })
    .set(SPEECH_TL1_PLAYBTN, { x: 0, y: 0 })


const panel4Tl = () => gsap.timeline({ id: "s2p4", paused: false, smoothChildTiming: true })
    .call(console.log, ["s2p4"])

    .add(panel4Tl_A(), 's2p4')
    .addLabel('s2p4_B', '>')

    // Animate orange taniwhas head/arms back & forward (head left & right, arms down to horizontal)
    .add(rockTaniwhaTl(TANIWHA_ANIM_DURATION, TANIWHA_ANIM_REPEATS), 's2p4')

    .call(console.log, ["s2p4 finished"], '>');

export { panel4Tl }