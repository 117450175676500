import { parallaxTl, registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade, fadeOutSubTitle } from '../../../subtitles/subtitleEffects';
import { subtitleLength, subtitleFadeOutDelay } from '../../../subtitles/subtitleConstants';
import { cameraShift } from '../calcs';


registerSetSubtitleWithFade();
registerWalkHero();

const CAMERA_SHIFT = cameraShift();
const hero = document.getElementById('hero');
const wakaCrew = [hero, '#beached-waka'];
const heroToBoatDuration = 7;
const container = document.getElementById('container');


const panel6Tl = () => gsap.timeline({ id: "panel-6-beach", paused: false, smoothChildTiming: true })
    .call(console.log, ["p6"])
    //SET STUFF 

    //nuku walks down onto the boat AND gets smaller - same size as other characters
    .walkHero(hero, { distance: 1050, duration: heroToBoatDuration, freezeContainer: false, parallax: true }) // 130px/s
    .add("heroWalkToBoat", '<')
    .to(hero, { scale: 0.17, bottom: '-4%', duration: heroToBoatDuration }, 'heroWalkToBoat')

    
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 16 - 1, skipFadeOut: true, x: '+=620px', scale: 1, bottom: 'unset', top: '5%', width: 395, left: 3876, height: 150 }, `${heroToBoatDuration*0.6}`)//after 12s pause

    .setSubtitleWithFade('#top-narration-slot-2', { subTitle: 17 - 1, skipFadeOut: true, x: '+=620px', scale: 1, top: '5%', width: 524, left: 4268, height: 150 }, `+=${subtitleLength}`)//after 12s pause

    .add(parallaxTl(null, 53, 1, true), `${heroToBoatDuration*0.6}`)

    //subtitles disappear
    .add(fadeOutSubTitle('#top-narration-slot-1', { subTitle: 16 - 1 }), `+=${subtitleLength}`)
    .add(fadeOutSubTitle('#top-narration-slot-2', { subTitle: 17 - 1 }), `+=${subtitleFadeOutDelay}`)

    //start boat ride
    //todo: maybe https://greensock.com/docs/v3/Plugins/MotionPathPlugin
    .walkHero(wakaCrew, { distance: 3000, duration: 32, walkLegs: false, freezeContainer: true, parallax: true }) // 93.75px/s waka
    .to(container, { x: `-=${1300 - CAMERA_SHIFT}`, duration: 8 }, '<')
    .to(wakaCrew, { rotation: "350_ccw", duration: 3, yoyo: true, repeat: 8 }, '<')
    .to('#container', { opacity: 0, duration: 16 }, '>-5')
    .to('#intro-container-2', { opacity: 1, duration: 3 }, '<')
export { panel6Tl }