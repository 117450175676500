let max_wheel_delta_y = 0;
const VERTICAL_SCROLL_SPEED = 100;

export const controlTimelineByScroll = (tl, starts = [0]) => {
    const start = starts[0];
    //it's debateable what is best here - debounce, throttle, or raf pattern below.=
    console.log('scrollCtrl', start);
    tl.seek(start);
    let ticking = false;
    const totalDuration = tl.totalDuration();
    let newPos = start / totalDuration;
    console.log(`totalDuration:${totalDuration}`)
    //set scroller width based on length of timeline
    const pixelsPerSecond = 150
    const totalScrollWidthPx = totalDuration * pixelsPerSecond;

    console.log(`width of scroller is:${totalScrollWidthPx}px`);
    document.documentElement.style.setProperty('--total-scroll-width-px', `${totalScrollWidthPx}px`);
    window.addEventListener('scroll', () => {
        if (window.pauseScrolling) return;
        const temp = 1 / ((totalScrollWidthPx - window.innerWidth) / window.scrollX); //don't divide by 0!
        newPos = temp < 0 ? 0 : temp > 1 ? 1 : temp;
        requestTick();
    })


    // TODO: support vertical touch-event to trigger horizontal scroll: https://stackoverflow.com/a/8927972/232613
    // TLDR: Listen for `touchstart` and `touchmove` event, listening for `event.touches[0].pageX`


    // Scroll horizontally through animation when up&down scrolling with mouse
    window.addEventListener('wheel', e => {
        if (window.pauseScrolling) return;
        // Stop if wheel moving horizontally anyway
        if (Math.abs(e.deltaY) < Math.abs(e.deltaX) * 3) return;

        max_wheel_delta_y = Math.max( Math.abs(e.deltaY), max_wheel_delta_y);
        const delta_y = e.deltaY / ((max_wheel_delta_y || 1));
        // console.log('wheel', rawDeltaY, delta_y);
        // console.log(e);

        if ( Math.abs(delta_y) > .1 ) {
            const MAX_AMT = 40;
            const amt = Math.min(delta_y * VERTICAL_SCROLL_SPEED, MAX_AMT);
            // console.log('vertical scroll', amt, ' - raw:', Math.abs(e.deltaY));
            window.scrollBy(amt, 0);
        }
    });
    
    console.log('scrollCtrl', start, newPos, 0);
    starts.forEach((start, index) => {
        const timeout = 1000 + 100 * index;
        window.setTimeout(
            () => {
                newPos = start / totalDuration;
                console.log('scrollCtrl', start, newPos, timeout);
                window.scrollTo( newPos * (totalScrollWidthPx - window.innerWidth), 0 );
            },
            timeout
        );
    });

    const requestTick = () => {
        if (!ticking) {
            requestAnimationFrame(() => {
                ticking = false;
                tl.totalProgress(newPos);//totalProgress is between 0 and 1.  totalTime is in seconds.
            });
        }
        ticking = true;
    }
}