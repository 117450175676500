export const NORMAL_RATIO = 1+1/3;
export const BORDER_WIDTH = 16;
export const ANIM_HEIGHT = 735;
export const FULL_HEIGHT = ANIM_HEIGHT + BORDER_WIDTH * 2;

export const NUKU_SCALE_NORMAL = 0.55;

export const animationSizes = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    if (!windowWidth || !windowHeight) return null;

    const windowRatio = windowWidth / windowHeight;

    const mobileScale = (windowHeight - BORDER_WIDTH) / ANIM_HEIGHT;
    const desktopScale = (windowHeight - BORDER_WIDTH) / FULL_HEIGHT;
    const animationWidth = windowHeight > FULL_HEIGHT ? windowWidth : windowWidth / mobileScale;
    const animationHeight = Math.min(windowHeight, ANIM_HEIGHT + BORDER_WIDTH);
    const normalWidth = Math.min(FULL_HEIGHT, windowHeight) * NORMAL_RATIO;
    const extraWidth = animationWidth - normalWidth;
    const fullWidthScale = windowRatio / NORMAL_RATIO;

    return {
        window: {
            width: windowWidth,
            height: windowHeight,
            ratio: windowRatio
        },
        animation: {
            width: animationWidth,
            height: animationHeight,
            extraWidth,
            mobileScale,
            desktopScale,
            fullWidthScale,
        },
    };
}