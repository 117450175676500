import { registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { panel3ContainerX } from '../../scene3a/calcs'; // TODO: move to scene3b calcs
import { P2_NUMS } from '../calcs';

registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3b = document.querySelector('#scene3b');

const CONTAINER_OFFSET = -panel3ContainerX();
const QUICK = 0.1;



const panel2Tl_A = () => gsap.timeline({ id: "scene-3b-panel-2", paused: false, smoothChildTiming: true })

    .add('s3bp2')

    .walkHero('#s3b-hero', {
        distance: P2_NUMS.WALK_DIST_1,
        duration: P2_NUMS.WALK_DUR_1,
        walkWithShoot: true,
        freezeContainer: false,
        parallax: -1.0,
        idPrefix: 's3b-',
    }) // 10px/s

    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 48 - 1, x: CONTAINER_OFFSET + P2_NUMS.WALK_DIST_1 + 420, y: 50, width: 400 }, '>-20')

    // Panel finished
    .call(console.log, ["s3bp2 finished"], '>');


const panel2Tl = () => gsap.timeline({ id: "s3bp2_master", paused: false, smoothChildTiming: true })
    .add( panel2Tl_A(), 's3bp2_master' );

export { panel2Tl }