import { registerSetSubtitleWithFade, registerThoughtBubble } from '../../../subtitles/subtitleEffects';
import { subtitleLength } from '../../../subtitles/subtitleConstants';
import { skyLightningTl } from '../../../animation/animationEffect';
import { soundNames, toggleSoundPlaying } from '../../../audio/sounds';


registerSetSubtitleWithFade();
registerThoughtBubble();

const BACKGROUND_SEA_POSITION = 5570;
const QUICK = 0.1;

const KARAKIA_SUB_DUR = subtitleLength;
const KARAKIA_PARTS = 10;
const KARAKIA_DUR = KARAKIA_SUB_DUR * KARAKIA_PARTS;
const KARAKIA_SUBTITLE_SCALE_X = 1.08;
const KARAKIA_SUBTITLE_SCALE_Y = 1.2;

const panel2Tl = () => gsap.timeline({ id: "s2p2", paused: false, smoothChildTiming: true })
    .add("s2p2")
    .call(console.log, ["s2p2"])

    // Rock boat?
    // .walkHero(WAKA_CREW, { distance: WALK_DIST_1, duration: 27.2, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: false })    

    // Raise arms
    .to('#boat-nuku-turn-cycle', { display: 'none' }) //set turn to be visible AND play after NO pause  
    .to('#boat-nuku-turned', { display: 'inline-block', opacity: 1 }) //set turn to be visible AND play after NO pause   

    // Karakia
    .add("s2p2_karakia")
    .setSubtitleWithFade('#speech-slot-bl1-TRM-only', { subTitle: 21 - 1, x: BACKGROUND_SEA_POSITION - 200, y: 70, scaleX: KARAKIA_SUBTITLE_SCALE_X, scaleY: KARAKIA_SUBTITLE_SCALE_Y, width: 365, height: 134, duration: KARAKIA_SUB_DUR }, `s2p`)
    .to('.s2-dark-clouds', { opacity: 1, duration: 10 }, 's2p2_karakia')
    .call(toggleSoundPlaying, [ soundNames.thunder, true ], 's2p2_karakia')
    .add( skyLightningTl('#sky', 11), 's2p2_karakia' )
    .add( skyLightningTl('#sky', 17), 's2p2_karakia+=35' )
    .add( skyLightningTl('#sky', 11), 's2p2_karakia+=80' )
    .add("s2p2_after_karakia")

    // Kuri thought bubble
    .thoughtBubble('#thought-bubble-container-topleft', { subTitle: 22 - 1, x: 2130, y: 300, duration: subtitleLength }, `s2p2+=${KARAKIA_DUR * 2/3 - 6}` )

    // Narator
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 23 - 1, x: BACKGROUND_SEA_POSITION + 100, y: 70, duration: subtitleLength })
    .add( skyLightningTl('#sky', 11), '<' )

    .call(console.log, ["s2p2 finished"], '>');

export { panel2Tl }