import { panel1Tl } from './panel1/panel1';
import { panel2Tl } from './panel2/panel2';
import { panel3Tl } from './panel3/panel3';

import { dancingChildrenShadowsTl, manuFlapWingsTl, sideWindyHairTl, kuriFlappingEarsCycleTl } from '../../animation/animationEffect';


const manuX = 3700;
const FLY1_DUR = 200;
const FLY1_DIST = 2500;
const FLY2_DUR = 174;
const FLY2_DIST = 1250;
const FLY3_DUR = 200;
const FLY3_DIST = 3550;

const windyHairTlAutoplay = sideWindyHairTl('#s3-nuku-windy-hair');

const animateKuriTlAutoplay = gsap.timeline({ paused: false, smoothChildTiming: true, repeat: -1 })
    .add(kuriFlappingEarsCycleTl('#s3-waka-kuri-flapping-ears', 10, 2));

const scene3aAnimationsTl = () => gsap.timeline({ id: "scene-3a-anims" })
    .call(() => {
        windyHairTlAutoplay.play();
        animateKuriTlAutoplay.play();
    }, [])
    .add( dancingChildrenShadowsTl('#s3-dancing-children-shadows', 30), '<')
    .add( manuFlapWingsTl('#s3-manu1', FLY1_DUR, 25, manuX, 195, 45, `+=${FLY1_DIST}`, '-=0', 0.5, false), 0)
    .add( manuFlapWingsTl('#s3-manu1', FLY2_DUR + 4, 21, null, null, 65, `+=${FLY2_DIST}`, '-=0', 0.5, false), FLY1_DUR)
    .add( manuFlapWingsTl('#s3-manu1', FLY3_DUR, 29, null, null, 0, `+=${FLY3_DIST}`, '-=0', 0.5, false), FLY1_DUR + FLY2_DUR + 4)

    .add( manuFlapWingsTl('#s3-manu2', FLY1_DUR + 2, 25, manuX - 100, 155, 45, `+=${FLY1_DIST}`, '-=0', 0.4, false), 1)
    .add( manuFlapWingsTl('#s3-manu2', FLY2_DUR + 2, 21, null, null, 65, `+=${FLY2_DIST}`, '-=0', 0.4, false), 1 + FLY1_DUR + 2)
    .add( manuFlapWingsTl('#s3-manu2', FLY3_DUR, 29, null, null, 0, `+=${FLY3_DIST}`, '-=0', 0.5, false), FLY1_DUR + 2 + FLY2_DUR + 2)

    .add( manuFlapWingsTl('#s3-manu3', FLY1_DUR + 5, 25, manuX - 100, 225, 45, `+=${FLY1_DIST}`, '-=0', 0.53, false), 2)
    .add( manuFlapWingsTl('#s3-manu3', FLY2_DUR, 21, null, null, 65, `+=${FLY2_DIST}`, '-=0', 0.53, false), FLY1_DUR + 5)
    .add( manuFlapWingsTl('#s3-manu3', FLY3_DUR, 29, null, null, 0, `+=${FLY3_DIST}`, '-=0', 0.5, false), 2 + FLY1_DUR + 5 + FLY2_DUR);


const scene3aPanelsTl = () => gsap.timeline({ id: "scene-3a-panels" })
    .add(panel1Tl())
    .add(panel2Tl())
    .add(panel3Tl())
    .call(() => console.log('s3a finished'));

const scene3aTl = () => gsap.timeline({ id: "scene-3a" })
    .add('scene-3a-master')
    .add(scene3aPanelsTl(), 'scene-3a-master')
    .add(scene3aAnimationsTl(), 'scene-3a-master');

const scene3aTlSetup = () => {};

export { scene3aTl, scene3aTlSetup }