import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { bendCycleTl, reverseBendCycleTl, rockTaniwhaTl } from '../../../animation/animationEffect';

registerSetSubtitleWithFade();

const BACKGROUND_SEA_POSITION = 5570;
const NET_BREAK_DUR = 28;
const TANIWHA_ANIM_REPEATS = 97;
const TANIWHA_ANIM_DURATION = 2;
const SPEECH_SLOT = '#speech-slot-br1';
const SPEECH_PLAYBTN = `${SPEECH_SLOT} .voiceover-btn`;
const WAKA_CREW = ['#waka'];

const panel5Tl_A = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    // Narator "With urgency and haste"
    .setSubtitleWithFade('#top-narration-slot-3', { subTitle: 33 - 1 })
    
    // Nukutawhiti turns around, bends over & cuts net
    .set('#boat-nuku-turn-cycle', { opacity: 0 })
    .set('#waka-hero', { rotateY: 180 }, '<')

    .set('#boat-nuku-turn-cycle', { opacity: 0 })
    .set('#nuku-side-windy-hair', { opacity: 0 })
    .add(bendCycleTl('#boat-nuku-bend-cycle'), '<')

    .add(reverseBendCycleTl('#boat-nuku-bend-cycle'), '>8')
    .set('#boat-nuku-turn-cycle, #boat-nuku-bend-cycle, #nuku-side-windy-hair', { opacity: 0 })
    .set('#nuku-side-windy-hair', { opacity: 1 })

    // Net breaks
    .set('#waka-net-right', { transformOrigin: 'top right' })
    .to('#waka-net-left', { x: '-=200', y: '-=80', duration: NET_BREAK_DUR }, '<')
    .to('#waka-net-middle', { x: '-=40', y: '+=80', duration: NET_BREAK_DUR }, '<')
    .to('#waka-net-right', { rotation: '-=60', y: '+=80', duration: NET_BREAK_DUR }, '<')
    
    // Narator "Suddenly the waka shot forward"
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 34 - 1 }, '<9')

    // Waka shoots forward (rock & net move left)
    .walkHero(WAKA_CREW, { distance: 1000, duration: 10, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: false }, '<') // 40px/s waka
    .to(WAKA_CREW, { x: `-=${1000}`, duration: 10, ease: ExpoScaleEase.config(1, 2), parallax: false })
    .to('#waka-net, #rock-with-waves', { x: '-=600', duration: 10, ease: ExpoScaleEase.config(1, 2) }, '<')

    // Nukutawhiti "thank you Taniwha"
    // TODO: position play btn with css instead of here
    .set(SPEECH_PLAYBTN, { x: 2, y: -7 }) // Move play btn to right place.
    .set(`${SPEECH_SLOT} > *`, { scaleX: 0.8, scaleY: 0.6 })
    .setSubtitleWithFade(SPEECH_SLOT, { subTitle: 35 - 1, x: BACKGROUND_SEA_POSITION - 170, y: 170, scaleX: 1.2, scaleY: 1.6 })
    .setSubtitleWithFade(SPEECH_SLOT, { subTitle: 36 - 1, scaleY: 1.1 })
    .setSubtitleWithFade(SPEECH_SLOT, { subTitle: 37 - 1, scaleY: 1.1 })
    .set(SPEECH_PLAYBTN, { x: 0, y: 0 })

    // Narator "With that crisis averted"
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 38 - 1, width: 555 })
    // Face Nuku forwards
    .set('#waka-hero', { rotateY: 0 }, '<')

    // Taniwha move to the left, out of sight
    .to('#taniwha_purple', { x: '-=170', duration: 25, ease: ExpoScaleEase.config(1, 2) }, '<5')
    .to('#taniwha_green', { x: '-=170', duration: 20, ease: ExpoScaleEase.config(1, 2) }, '<')
    .to('#taniwha_orange', { x: '-=400', duration: 20, ease: ExpoScaleEase.config(1, 2) }, '<')
    .to('#moana-characters', { display: 'none' })


const BOATROCK_DUR = 20;
const panel5Tl = () => gsap.timeline({ id: "s2p5", paused: false, smoothChildTiming: true })
    .call(console.log, ["s2p5"])

    .add(panel5Tl_A(), 's2p5')
    .add(rockTaniwhaTl(TANIWHA_ANIM_DURATION, TANIWHA_ANIM_REPEATS), 's2p5')
    .to(WAKA_CREW, { rotation: -14, duration: 5, yoyo: true, repeat: BOATROCK_DUR/5 }, `>-${BOATROCK_DUR}`)

    .call(console.log, ["s2p5 finished"], '>');

export { panel5Tl }