import { animationSizes } from '../../animation/animationSizes';

export const wideShotScale = () => {
    const sizes = animationSizes();
    const DEFAULT_SCALE = 1.6;
    return sizes ?
        sizes.animation.width / 700
        : DEFAULT_SCALE;
};

export const extremeWideShotScale = () => {
    const sizes = animationSizes();
    const DEFAULT_SCALE = 0.23;
    return sizes ?
        sizes.animation.width / 5200
        : DEFAULT_SCALE;
};

// Calc #container x so that RHS of scene sits on RHS of screen regardless of screen aspect ratio
export const panel3ContainerX = () => {
    const sizes = animationSizes();
    if (!sizes) return -5200;

    // TODO: Simplify. Bro. This was way too hard to figure out and doesnt fully make sense anyhow.
    return -4974 + sizes.animation.extraWidth - (164 / sizes.animation.mobileScale); // Why 164??
};