// === Sprite helper funcs ===

const spriteBgPos = (sprite_width, sprite_num, offset) =>
    sprite_width
        ? `${-sprite_width * sprite_num - (offset || 0)}px 0`
        : `${sprite_num} 0`;

const spriteAnimationTl = (target, config = { width: 780, start: 0, end: 1, duration: 10, num_sprites: null, x: 0, y: 0, scale: 1.0 }) => {
    const tl = gsap
        .timeline(config.timelineVars || {})
        .set(target, { backgroundPosition: spriteBgPos(config.width, config.start, config.offset) });
    if (!config.skipOpacity)
        tl.set(target, { opacity: config.opacity || 1 });

    // Set initial params if specified
    ['x', 'y', 'scale', 'repeat', 'paused'].forEach(param => {
        if (config[param]) tl.set(target, { [param]: config[param] });
    });

    // Animate the background position
    tl.to(target, {
            duration: config.duration,
            backgroundPosition: spriteBgPos(config.width, config.end, config.offset),
            ease: `steps(${config.width ? config.end - config.start : config.num_sprites - 1})`,
            repeat: config.repeat || 0,
            paused: config.paused || false,
        }).yoyo(config.yoyo || false);

    return tl;
}


// === Animation timelines ===

//translate various backgrounds seperately
var parallaxTl = (distance, duration, scale = 1.0, standing_still = false) => {
    distance = distance !== null
        ? distance
        : duration * 9.5;
    const parallaxDistance = distance / 10 * scale;
    const tl = gsap.timeline({ id: "parallax", paused: false });
    tl.to("#clouds", { x: `+=${parallaxDistance}px`, ease: Linear.easeNone }).yoyo(true).duration(duration);

    if (!standing_still) {
        tl.to(".heroWalkNearBackground", { x: `-=${parallaxDistance * 3}px`, ease: Linear.easeNone }, '<').yoyo(true).duration(duration);
        tl.to(".heroWalkBackground", { x: `-=${parallaxDistance}px`, ease: Linear.easeNone }, '<').yoyo(true).duration(duration);
        tl.to(".heroWalkFarBackground", { x: `-=${parallaxDistance / 3}px`, ease: Linear.easeNone }, '<').yoyo(true).duration(duration);
        tl.to(".heroWalkFarSlowBackground", { x: `-=${parallaxDistance / 4}px`, ease: Linear.easeNone }, '<').yoyo(true).duration(duration);
    }

    return tl;
}

//nuku turn cycle
const turnCycleTl = (target = "#nuku-turn-cycle") => spriteAnimationTl(target, { start: '0%', end: '100%', duration: 10, num_sprites: 6 });

const reverseTurnCycleTl = (target = "#nuku-turn-cycle") => spriteAnimationTl(target, { start: '100%', end: '0%', duration: 10, num_sprites: 6 });

// Nuku bend cycle
const BEND_SPRITE_WIDTH = 780;
const bendCycleTl = (target) => spriteAnimationTl(target, { width: BEND_SPRITE_WIDTH, start: 0, end: 4, duration: 10, repeat: 0, paused: false });

const reverseBendCycleTl = (target) => spriteAnimationTl(target, { width: BEND_SPRITE_WIDTH, start: 4, end: 0, duration: 10, repeat: 0, paused: false });

const kuriFlappingEarsCycleTl = (target, duration, speed = 5) => spriteAnimationTl(target, { start: '0%', end: '100%', duration: speed, num_sprites: 6, repeat: duration / speed });

//cycles through parts of background sprite.  use play and pause methods.
const walkTl = (target = '#nuku-walking') => spriteAnimationTl(target, { start: '0%', end: '100%', duration: 4, num_sprites: 7 });

const SHOOT_SPRITE_WIDTH = 391;
const walkWithShootTl = (target = '#nuku-walking-shoot') => spriteAnimationTl(target, { width: SHOOT_SPRITE_WIDTH, start: 0, end: 6, duration: 4 });
const nukuRaiseShootsTl = (target = '#s3b-nuku-walking-shoot', duration = 10) => spriteAnimationTl(target, { width: SHOOT_SPRITE_WIDTH, start: 7, end: 11, duration });
const nukuLowerShootsTl = (target = '#s3b-nuku-walking-shoot', duration = 10) => spriteAnimationTl(target, { width: SHOOT_SPRITE_WIDTH, start: 11, end: 7, duration });

const sideWindyHairTl = (target = '#nuku-windy-hair') => spriteAnimationTl(target, { offset: 150, width: 203, start: 0, end: 16, duration: 1, repeat: -1, paused: false });

const rockTaniwhaBackAndForwardTween = (toRotation, duration, repeat) =>
    ({ rotation: toRotation, duration, repeat, yoyo: true, ease: "power1.inOut" });


// Make children dance
const dancingChildrenShadowsTl = (target = '#dancing-children-shadows', repeat = 3) => spriteAnimationTl(target, {
    start: '0%', end: '100%', opacity: 0.6, duration: 20, num_sprites: 20, repeat
});

const fallingFeatherTl = (target = '#s3-falling-feather', repeat = 3) =>
    spriteAnimationTl(target, {
        start: '0%', end: '100%', duration: 15, num_sprites: 6, repeat, timelineVars: { paused: false, smoothChildTiming: true }
    });
    
const manuFlapWingsTl = (target = '#s3-manu1', duration, wingFlaps = 3, x, y, moveDelay, x2 = '+=1000', y2 = '+=80', scale = 0.5, fadeAtEnd) =>
    spriteAnimationTl(target, {
        x, y, scale,
        start: '0%', end: '100%',
        num_sprites: 16,
        duration: duration / wingFlaps,
        repeat: wingFlaps,
        timelineVars: { paused: false, smoothChildTiming: true }
    })
    .to(target, { x: x2, y: y2, duration: duration - moveDelay }, `<${moveDelay}`)
    .to(target, { opacity: fadeAtEnd ? 0 : 1, duration: 5 }, '>-5');

const rockTaniwhaTl = (duration, repeat) => gsap.timeline()
    // Animate orange taniwhas head/arms back & forward (head left & right, arms down to horizontal)
    .fromTo('#taniwha_orange_body', { rotation: -27, }, rockTaniwhaBackAndForwardTween(4, duration, repeat))
    .fromTo('#taniwha_orange_head', { rotate: 5 }, rockTaniwhaBackAndForwardTween(10, duration, repeat), `<`)
    .fromTo('#taniwha_orange_left_arm', { rotation: 35, }, rockTaniwhaBackAndForwardTween(-30, duration, repeat), `<`)
    .fromTo('#taniwha_orange_right_arm', { rotation: -20, }, rockTaniwhaBackAndForwardTween(4, duration, repeat), `<`)
    .fromTo('#taniwha_brown, #taniwha_purple, #taniwha_green', { rotation: 4, stagger: 0.5 }, rockTaniwhaBackAndForwardTween(-27, duration, repeat), `<0.5`);

const registerWalkHero = () => gsap.registerEffect({
    name: "walkHero",
    effect: (targets, config) => {
        const id = (suffix) => ('#' + (config.idPrefix || '') + suffix);

        const tl = gsap.timeline({ id: "walk-hero-effect" })

        if (config.walkLegs) {
            tl.set(id('nuku-walking'), { opacity: 1 })
                .set(id('nuku-profile'), { opacity: 0 })
                .add(walkTl(id('nuku-walking')).repeat(Math.ceil(config.distance / 600)).totalDuration(config.duration), 0)
        }

        if (config.walkWithShoot) {
            tl.set(id('nuku-walking-shoot'), { opacity: 1 })
                .set(id('nuku-profile'), { opacity: 0 })
                .add(walkWithShootTl(id('nuku-walking-shoot')).repeat(Math.ceil(config.distance / 600)).totalDuration(config.duration), 0)
        }

        if (config.sideWindyHair) {
            tl.set([id('nuku-side-windy-hair'), id('nuku-windy-hair')], { opacity: 1 })
                .set([id('nuku-profile'), id('nuku-turn-cycle')], { opacity: 0 })
                .set(id('nuku-windy-hair'), { opacity: 1 }, `>${config.duration}`)
        } else {
            tl.set(id('nuku-side-windy-hair'), { opacity: 0 })
        }

        tl.to(targets, { x: `+=${config.distance}`, duration: config.duration, ease: 'none' }, 0)
        if (!config.freezeContainer) {
            tl.to("#container", { x: `-=${config.distance}`, duration: config.duration, ease: 'none' }, 0)
        }
        if (config.parallax) {
            tl.add(parallaxTl(config.distance, config.duration, parseFloat(config.parallax) || 1.0, config.skip_parallax_fg || false), 0)
        }

        tl.set(id('nuku-walking'), { opacity: 0 })
        if (!config.sideWindyHair) {
            tl.set(id('nuku-profile'), { opacity: 1 })
        }

        return tl;
    },
    defaults: { parallax: true, freezeContainer: false, walkLegs: true },
    extendTimeline: true
});

const skyLightningTl = (skyTarget = '#sky', repeat = 5) => gsap.timeline()
    .to(skyTarget, { backgroundColor: 'white' })
    .duration(2)
    .yoyo(true)
    .repeat(repeat);


export {
    skyLightningTl,
    parallaxTl,

    registerWalkHero,
    turnCycleTl, reverseTurnCycleTl,
    bendCycleTl, reverseBendCycleTl,
    nukuRaiseShootsTl, nukuLowerShootsTl,
    sideWindyHairTl,

    kuriFlappingEarsCycleTl,
    dancingChildrenShadowsTl,
    fallingFeatherTl,
    manuFlapWingsTl,
    rockTaniwhaTl,
}