import { parallaxTl } from '../../animation/animationEffect';
import { panel1Tl } from './panel1/panel1';
import { panel2Tl } from './panel2/panel2';
import { panel3Tl } from './panel3/panel3';
import { panel4Tl } from './panel4/panel4';
import { panel5Tl } from './panel5/panel5';
import { panel6Tl } from './panel6/panel6';


const BACKGROUND_SEA_POSITION = 5570;

const scene2Tl = () => gsap.timeline({ id: "scene-2" })
    // Position dark clouds
    .set('.s2-dark-clouds', { scale: .15, opacity: 0, zIndex: 27 })
    .set('#s2-dark-clouds2', { x: BACKGROUND_SEA_POSITION + 200, y: -250 })
    .set('#s2-dark-clouds1', { x: BACKGROUND_SEA_POSITION - 760, y: -120 })
    .set('#s2-dark-clouds3', { x: BACKGROUND_SEA_POSITION + 700, y: -270, scale: .25, zIndex: 21 })

    .add(panel1Tl())
    .add(panel2Tl())
    .add(panel3Tl())
    .add(panel4Tl())
    .add(panel5Tl())
    .add(panel6Tl())

    // Move the clouds
    .add( parallaxTl(null, 848, 1, false), '0')

    .call(() => console.log('s2 finished'));

const scene2TlSetup = () => {};

export { scene2Tl, scene2TlSetup }