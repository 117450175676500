
//waterfall sprite
const numberOfImagesInWaterfallSprite = 6;
export const waterfallSprite = gsap.to("#waterfall", {
    duration: 0.3,
    backgroundPosition: "100% 0%",
    ease: SteppedEase.config(numberOfImagesInWaterfallSprite),
    repeat: -1,
    paused: false
});

//bark chips sprite - left hand side
const numberOfImagesInBarkSpriteLhs = 40;
export const barkChipsSpriteLhs = gsap.to("#bark-chips-lhs", {
    duration: 2,
    backgroundPosition: "100% 0%",
    ease: SteppedEase.config(numberOfImagesInBarkSpriteLhs),
    repeat: -1,
    paused: true
});

//bark chips sprite - right hand side
const numberOfImagesInBarkSpriteRhs = 40;
export const barkChipsSpriteRhs = gsap.to("#bark-chips-rhs", {
    duration: 2,
    backgroundPosition: "100% 0%",
    ease: SteppedEase.config(numberOfImagesInBarkSpriteRhs),
    repeat: -1,
    paused: true
});

//Putatara
const numberOfImagesInPutataraSprite = 4;
export const putataraSprite = gsap.to("#putatara", {
    duration: 4,
    backgroundPosition: "100% 0%",
    ease: SteppedEase.config(numberOfImagesInPutataraSprite),
    repeat: -1,
    paused: false
});
