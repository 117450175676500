// It's actually 30 but this works better, as the images are all but pre-loaded
// by the browser as soon as preloading them starts in JS
const REQ_TOTAL = 15;
let reqNum = 0;

const setLoadingMsg = (msg) => {
	setTimeout(() => {
        if (reqNum <= REQ_TOTAL) {
            document.querySelectorAll(".loading-progress").forEach(($el) => {
                $el.innerText = msg;
            });
        }
        // const now = new Date();
        // const time = now.toLocaleTimeString() + "." + now.getMilliseconds();
        // console.log("loading", msg, time);
	});
};

export const loadingBeginning = () => {
	setLoadingMsg(`0%`);
}

export const loadProgress = () => {
    if (reqNum < REQ_TOTAL) {
        reqNum++;
	    setLoadingMsg(`${Math.round((reqNum * 100) / REQ_TOTAL)}%`);
    }
};
