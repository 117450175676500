
import { toggleFxVolume, toggleBgMusicVolume, triggerInitialAudio } from '../audio/sounds';
import { toggleLanguage } from '../subtitles/language';
import { loadProgress } from '../intro-page/loading';

let sound;
const initControls = () => {
    console.log('initControls');
    preloadImages();
    initSound();
    initSoundButtons();
    initLoadingScreenControls();
    initJump();
}

const preloadImages = () => {
    const preloadImg = (src) => {
        const img = new Image();
        img.onload = () => {
            console.log(`loaded img: ${img.width}x${img.height}`);
            loadProgress();
        };
        img.src = src;
    };

    preloadImg(require('../../assets/props/beached-waka.png'));
    preloadImg(require('../../assets/props/waka-sail.png'));
    preloadImg(require('../../assets/props/waka-body.png'));
    preloadImg(require('../../assets/props/net/net-left.png'));
    preloadImg(require('../../assets/props/net/net-middle.png'));
    preloadImg(require('../../assets/props/net/net-right.png'));
    preloadImg(require('../../assets/landscape/wave-1.png'));
    preloadImg(require('../../assets/props/dancing-children@365x500-8.png'));
    preloadImg(require('../../assets/Nukutawhiti/nuku-profile1_png8_1054h.png'));
    preloadImg(require('../../assets/Nukutawhiti/nukutawhi_turn_png8_1050h_eyes.png'));
    preloadImg(require('../../assets/Nukutawhiti/nuku-turned_png8_1054h.png'));
    preloadImg(require('../../assets/landscape/landscape-images-750h/clouds_png8_750h_.png'));
    preloadImg(require('../../assets/landscape/landscape-images-750h/merged-back-without-waterfall_png8_750h.png'));
    preloadImg(require('../../assets/Nukutawhiti/Nukutawhiti-45-Bend-transparent_780w_1054h.png'));
    preloadImg(require('../../assets/Nukutawhiti/hair-and-body/nuku-profile1_png24_NOHAIR@1054h-8.png'));
    preloadImg(require('../../assets/Nukutawhiti/hair-and-body/nuku-hair-blowing@150-png8.png'));
    preloadImg(require('../../assets/scene3a/Animated/Feathers-Falling-321x450.png'));
    preloadImg(require('../../assets/scene3a/Animated/Manu-207x124.png'));
    preloadImg(require('../../assets/scene3a/Ocean-Layers/Scene-3.a-Light-blue-waves_big.png'));
}

const initSound = () => {
    Howler.mute(true);
}

const initJump = () => {
    let jumping = false;

    const jumpHero = (target, dist) =>
        gsap.to(target, { y: `-=${dist}`, duration: 0.30, onComplete: () => { jumping = false; } }).yoyo(true).repeat(1);
    
    const jumpHeroes = () => {
        if (jumping) return;
        jumping = true;
        jumpHero('#hero, #s3b-hero', 150);
        jumpHero('#waka-hero, #s3-waka-hero', 30);
    }

    document.querySelectorAll('#hero, #plants, #waka-hero, #s3-waka-hero, #s3b-hero').forEach($hero => $hero.addEventListener('click', (e) => {
        jumpHeroes();
    }));

    document.addEventListener('keyup', (e) => {
        if (e.keyCode == 32) jumpHeroes();
    });
}

const initLanguageButtons = () => {
    console.log('initControls');
    const langButtons = document.querySelectorAll('#language-buttons btn');
    langButtons.forEach(x => x.addEventListener('click', toggleLanguage));
}

const initSoundButtons = () => {
    console.log('initControls');
    const soundButtons = document.querySelectorAll('#sound-buttons > .fx');
    soundButtons.forEach(x => x.addEventListener('click', (e) => {
        toggleFxVolume();
        soundButtons.forEach(x => x.classList.toggle('active'))
    }))

    const backgroundSoundButtons = document.querySelectorAll('#sound-buttons > .background-sound');
    console.log('setting up fx buttons');
    backgroundSoundButtons.forEach(x => x.addEventListener('click', (e) => {
        console.log('toggling fx buttons');
        toggleBgMusicVolume();
        backgroundSoundButtons.forEach(x => x.classList.toggle('active'))
    }))
}

const startAnimation = () => {
    document.getElementById('sound-buttons').style.display = 'inline-block'
    // document.getElementById("loading-screen").style.display = 'none'; 
    gsap.timeline().to('#loading-screen', { opacity: 0, duration: 3 })
        .call(() => { document.getElementById("loading-screen").style.display = 'none'; });
    Howler.mute(false);
    triggerInitialAudio();// initial audio must be coupled to user action.
    document.getElementById("btnStart").style.display = 'none';
    gsap.set('html', { overflowX: 'scroll' }); //set at last minute to prevent funny behaviour while loading
    window.scrollTo(0, 0);
}

const initLoadingScreenControls = () => {
    console.log('init loading screen controls');
    const btnStarts = [...document.getElementsByClassName("btnStart")];
    btnStarts.forEach(x => x.addEventListener('click', startAnimation))
    const loadingIndicators = [...document.getElementsByClassName("loading-indicator")]
    loadingIndicators.forEach(x => x.style.display = 'none');
    btnStarts.forEach(x => x.style.display = 'inline-block');
}

export { initLanguageButtons, initControls, startAnimation };