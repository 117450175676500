import * as soundNames from '../../../audio/soundNames';
import { toggleSoundPlaying } from '../../../audio/sounds';
import { subtitleLength } from '../../../subtitles/subtitleConstants';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { filterHSV, NO_FILTER_HSV } from '../../../animation/domEffects';
import { kuriFlappingEarsCycleTl, registerWalkHero, skyLightningTl, turnCycleTl, sideWindyHairTl } from '../../../animation/animationEffect';
import { COLORS } from '../../../animation/styleConstants';


registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene = document.querySelector('.scene');
const WAKA = '#waka'
const WAKA_CREW = WAKA;
const SCENE_EXCEPT_SKY = ['.bg-wave', WAKA, '#clouds']

const BACKGROUND_SEA_POSITION = 4700;
const QUICK = 0.1;
const TOP_SLOT_WIDTH = 400;
const SLOT_GAP = 5;
const STORM_DURATION = 20;
const NUKU_SCALE = 0.05;
const WALK_DIST_1 = 1000;
const WALK_DIST_2 = 500;


const windyHairTlAutoplay = sideWindyHairTl('#nuku-windy-hair');

const animateKuriTlAutoplay = gsap.timeline({ paused: false, smoothChildTiming: true, repeat: -1 })
    .add(kuriFlappingEarsCycleTl('#waka-kuri-flapping-ears', 10, 2));

const panel1Tl_A = () => gsap.timeline({ id: "scene-2-panel-1-beach", paused: false, smoothChildTiming: true })

    .call(console.log, ["scene2 setup start"])
    .add('s2p1_setup')

    // Scene 2 music
    .call(() => {
        toggleSoundPlaying(soundNames.scene1Background)
        toggleSoundPlaying(soundNames.scene2Background)
        console.log;
    })

    // Switch background to scene 2
    .set($container, { scale: 1, opacity: 0 }, "s2p1_setup")
    .set($scene, { opacity: 1, transformOrigin: `${BACKGROUND_SEA_POSITION + WALK_DIST_1 + WALK_DIST_2 - 250}px 520px` }, "s2p1_setup")
    .set('.scene1-ground, #s1-background', { opacity: 1 }, "s2p1_setup") // hide scene1 content
    .to('.scene1-ground, #s1-background', { opacity: 0, duration: QUICK }, "s2p1_setup") // hide scene1 content
    .fromTo('.scene1-ground, #s1-background', { display: 'block' }, { display: 'none' }, "s2p1_setup") // hide scene1 content
    .set('.scene2-moana', {opacity: 0}, "s2p1_setup") // show scene 2
    .to('.scene2-moana', {opacity: 1, duration: QUICK}, "s2p1_setup") // show scene 2


    // Position props
    .set(WAKA, {opacity:1, x: -130, y: 0, z: 0, rotation: -14}, "s2p1_setup") // show these things
    .fromTo('#nuku-side-windy-hair', {opacity:0}, {opacity:1, duration:QUICK}, "s2p1_setup") // show these things
    .call(() => { 
        windyHairTlAutoplay.play();
        animateKuriTlAutoplay.play();
    }, [])
    .set('#waka-hero', { scale: NUKU_SCALE }, "s2p1_setup") // scale boat hero imgs
    .set($container, { x: -BACKGROUND_SEA_POSITION, opacity: 0 }, "s2p1_setup") // sea background
    .set([WAKA, '#clouds'], { filter: 'brightness(100%)' }, "s2p1_setup")
    .set('#mountains-1', { x: BACKGROUND_SEA_POSITION + 300, y: 400 }, "s2p1_setup") // grey mountains on left
    .set('#mountains-2', { x: BACKGROUND_SEA_POSITION + 800, y: 400 }, "s2p1_setup") // brown mountains on right
    .set(WAKA, { x: -130, y: 0, z: 0, rotation: -14 }, "s2p1_setup")
    // .fromTo('#hero', { scale: 1.7 }, { x: -400 + BACKGROUND_SEA_POSITION, y: -100, z: 0, rotation: -14, scale: NUKU_SCALE, duration: QUICK }, "s2p1_setup")
    // .to('#hero', { transformOrigin: '50% 81%', duration: QUICK }, "s2p1_setup")

    // Position subs
    // .to('.subtitle', { padding: '20px 25px' }, '<')
    .to('.narration', { height: 'unset' }, '<')

    // Beginning of panel 1
    .call(console.log, ["scene2 setup complete"])
    .add("s2p1", '>1')
    .call(console.log, ["s2p1"])

    // Fade in
    .to($container, { opacity: 1, duration: 16 }, 's2p1')

   
    // Boat rocking as it moves across screen
    .call(() => console.log("s2p1 walk boat"))
    .add('s2p1_boatrock')
    .walkHero(WAKA_CREW, { distance: WALK_DIST_1, duration: 28, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: false }, 's2p1_boatrock') // 35.7px/s waka
    .to(WAKA_CREW, { rotation: -4, duration: 5, yoyo: true, repeat: 14 }, 's2p1_boatrock')
    // .to(WAKA_CREW, { rotation: -9, duration: 1.5 })

    // Subs
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 18 - 1, x: BACKGROUND_SEA_POSITION, left: 0, width: TOP_SLOT_WIDTH, height: 140 }, `s2p1+=${subtitleLength*1}`)
    .setSubtitleWithFade('#top-narration-slot-2', { subTitle: 19 - 1, x: BACKGROUND_SEA_POSITION, left: TOP_SLOT_WIDTH + SLOT_GAP, width: TOP_SLOT_WIDTH }, `s2p1+=${subtitleLength*2}`)
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 20 - 1, left: BACKGROUND_SEA_POSITION + 620, width: 400 }, `s2p1+=${subtitleLength*3}`)
    // .fromTo($container, { transformOrigin: `50% 50%` }, { transformOrigin: `70% 60%` }) // the point on which to zoom/rotate, etc

    // Transition point
    .add("s2p1_transition", `s2p1+=${ subtitleLength*4 - 9 }`)

    // Move to right
    .walkHero(WAKA_CREW, { distance: WALK_DIST_2, duration: 8, sideWindyHair: true, walkLegs: false, freezeContainer: false, parallax: true }, "s2p1_transition") // 62.5px/s waka
    .walkHero(WAKA_CREW, { distance: 0, duration: 45, sideWindyHair: true, walkLegs: false, freezeContainer: false, parallax: true }, "s2p1_transition+=10") // 0px/s waka

    // Make scene go dark
    .fromTo([WAKA, '#clouds'], { filter: NO_FILTER_HSV }, { filter: filterHSV(0,40,1), duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-1', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,20,2) , duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-2', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,25,2) , duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-3', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,20,2) , duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-4', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,8,2), duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-5', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,25,2), duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-6', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,13,2), duration: STORM_DURATION }, "s2p1_transition")
    .fromTo('#bg-wave-7', { filter: NO_FILTER_HSV }, { filter: filterHSV(15,18,2), duration: STORM_DURATION }, "s2p1_transition")
    
    // Change sky colours
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_25, duration: STORM_DURATION*1/10 }, `s2p1_transition`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_50, duration: STORM_DURATION*1/10 }, `s2p1_transition+=${STORM_DURATION*1/10}`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_75, duration: STORM_DURATION*1/10 }, `s2p1_transition+=${STORM_DURATION*2/10}`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_100, duration: STORM_DURATION*2/10 }, `s2p1_transition+=${STORM_DURATION*3/10}`)

    // Lightning
    .add(skyLightningTl(), `s2p1_transition+=${STORM_DURATION*5/10}`)

    // Turn cycle
    .set('#nuku-side-windy-hair', {opacity: 0}, '-=20')
    .add(turnCycleTl('#boat-nuku-turn-cycle'), '-=20')
        
    // Zoom in to Nuku
    .fromTo($scene, { scale: 1 }, { scale: 3.0, ease: ExpoScaleEase.config(1, 2), duration: STORM_DURATION }, `s2p1_transition+=${STORM_DURATION*5/10}`)
    // TODO: Remove this dirty hack "forcing waka X position". Figure out why boat moves to wrong place (above) in this panel
    .to('#waka', { x: 1201, duration: STORM_DURATION }, '<')

    // Panel finished
    .call(console.log, ["s2p1 finished"], '>');

const panel1Tl = () => gsap.timeline({ id: "s2p1_master", paused: false, smoothChildTiming: true })
    .add(panel1Tl_A(), 's2p1_master')

export { panel1Tl };
