export const languageCodes = {
    TE_REO: 'TRM',
    ENGLISH: 'ENG',
}

window.lang = languageCodes.TE_REO;

export const toggleLanguage = () => {
    const langButtons = document.querySelectorAll('#language-buttons btn');

    langButtons.forEach(x => x.classList.toggle('active'));
    const translatables = [...document.querySelectorAll('.english, .teReo')];
    translatables.forEach(x => x.classList.toggle('active'));

    if (window.lang == languageCodes.TE_REO) {
        window.lang = languageCodes.ENGLISH;
    } else {
        window.lang = languageCodes.TE_REO;
    }
}