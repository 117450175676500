import { registerSetSubtitleWithFade, fadeOutSubTitle } from '../../../subtitles/subtitleEffects';
import * as sprites from '../../../animation/sprites';
import { toggleSoundPlaying, setSoundPosition, soundNames } from '../../../audio/sounds';
import { subtitleLength, subtitleFadeOutDelay } from '../../../subtitles/subtitleConstants';
import { registerWalkHero } from '../../../animation/animationEffect';

registerSetSubtitleWithFade();
registerWalkHero();

const container = document.getElementById('container');
const hero = document.getElementById('hero');
const walkpanel34Duration = 20;


const panel4Tl = () => gsap.timeline({ id: "panel-4-whare-waka", paused: false, smoothChildTiming: true })
    .call(console.log, ["p4"])
    .call(() => {
        sprites.barkChipsSpriteLhs.paused(!sprites.barkChipsSpriteLhs.paused());
        sprites.barkChipsSpriteRhs.paused(!sprites.barkChipsSpriteRhs.paused());
    })

    //set things at begionning
    //container same as panel 3 container
    .fromTo(container, { transformOrigin: `1800px 40%` }, { transformOrigin: `1800px 40%` }) //as per end of P3. should export constants probably
    // .set(container, { scale: 2 }) //set zoom -same as panel 3  



    //nuku walks into scene 4
    .walkHero('#hero', { distance: 150, duration: 5, freezeContainer: true, parallax: true }) // 30px/s
    .walkHero('#hero', { distance: 750, duration: walkpanel34Duration - 5, freezeContainer: true, parallax: true }) // 50px/s
    .add("heroWalkpanel34", {duration: walkpanel34Duration}, '<')

    

    //try to get Nuku back to the left hand side of the panel as he walks - undo freeze frame
    .to("#container", { x: `-=1600`, duration: walkpanel34Duration }, 5) // distance of 900 + 450 to get him back to the left side.
    .call(toggleSoundPlaying, [soundNames.carving])

    //narration box 1 appears
        
    //appear at 85% of walk duration
    //need to scale these down because of zoom factor of containing container
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 10 - 1, skipFadeOut: true, scale: 0.5, childScale: 1, top: '19%', bottom: 'unset', width: 520, left: 2403 }, `${walkpanel34Duration * 0.85}`)
    .call(console.log, [`${walkpanel34Duration * 0.85}`], '>')


    //scale Nuku down to walk into whare waka
    //resetting transform origin breaks stuff, leave as-is
    .to(hero, { scale: 0.15, y: "-288", duration: '4' }) //make him small
    .walkHero(hero, { distance: 530, duration: 5, freezeContainer: false, parallax: true }, '<') // 106px/s. walk him to the door
    .fromTo(hero, { opacity: 1 }, { opacity: 0 })


    //narration box 2 appears
    //need to scale these down because of zoom factor of containing container
    //after 12s pause to appreciate bark chips
    .setSubtitleWithFade('#top-narration-slot-2', { subTitle: 11 - 1, skipFadeOut: true, x:0, scale: 0.5, childScale: 1, top: '23%', bottom: 'unset', width: 'unset', left: 2791, transformOrigin: '0 0' }, '+=12')

    //narration box 3 appears
    //need to scale these down because of zoom factor of containing container
    .setSubtitleWithFade('#bottom-narration-slot-3', { subTitle: 12 - 1, skipFadeOut: true, scale: 0.5, childScale: 1, width: 'unset', bottom: '28.2%', left: 2498 }, `+=${subtitleLength}`)

    //nuku opacities in and exits whare waka
    .fromTo(hero, { opacity: 0 }, { opacity: 1 }, '+=12')//delay to read last narration box
    .walkHero(hero, { distance: 100, duration: 1, freezeContainer: false, parallax: true }, '<') // 100px/s. walk him out the door

    //narration boxes disappear AS Nuku walks out of panel 4 into panel 5
    .add(fadeOutSubTitle('#top-narration-slot-1', { subTitle: 9 }), '<')
    .add(fadeOutSubTitle('#top-narration-slot-2', { subTitle: 10 }), `+=${subtitleFadeOutDelay}`)
    .add(fadeOutSubTitle('#bottom-narration-slot-3', { subTitle: 11 }), `+=${subtitleFadeOutDelay}`)
    .call(setSoundPosition, [soundNames.scene1Background, 58], '>-3')
    .call(() => {
        sprites.barkChipsSpriteLhs.paused(!sprites.barkChipsSpriteLhs.paused());
        sprites.barkChipsSpriteRhs.paused(!sprites.barkChipsSpriteRhs.paused());
    }, [], '<')
    .to('#bark-chips-lhs', { opacity: 0, duration: 2 }, '<')
    .to('#bark-chips-rhs', { opacity: 0, duration: 2 }, '<')
    .call(toggleSoundPlaying, [soundNames.carving], '<');


export { panel4Tl }