export const waterfall = 'WATERFALL';
export const fire = 'FIRE';
export const scene1Background = 'SCENE1_BACKGROUND';
export const scene2Background = 'SCENE2_BACKGROUND';
export const scene3bBackground = 'SCENE3B_BACKGROUND'
export const creditsBackground = 'CREDITS_BACKGROUND'
export const moana = 'MOANA';
export const taiAo = 'TAIAO';
export const carving = 'CARVING';
export const walking = 'WALKING';
export const waterfall1 = 'WATERFALL1';
export const manu = 'MANU';
export const thunder = 'THUNDER';