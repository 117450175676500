import { registerWalkHero, turnCycleTl, reverseTurnCycleTl, skyLightningTl } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import * as soundNames from '../../../audio/soundNames';
import { toggleSoundPlaying } from '../../../audio/sounds';
import { subtitleLength } from '../../../subtitles/subtitleConstants';
import { COLORS } from '../../../animation/styleConstants';

registerSetSubtitleWithFade();
registerWalkHero();

const nukuStartPositionPanel2 = 550;
const SUB_ID4_SCALEY = 0.8;

const panel2Tl = () => gsap.timeline({ id: "panel2", paused: false, smoothChildTiming: true })
    .call(console.log, ["DBG p2"])
    //set timeline 2 stuff
    .set(hero, { x: nukuStartPositionPanel2 })
    .set('#speech-slot-bl1', { x: '400', opacity: 0 })
    .set('#nuku-turn-cycle', { opacity: 0 })

    //narration box from panel 1 disappear - DON'T NEED TO have had to SET POSITION FOR PANEL 2
    // PANEL 1 narration text 2 and 3 disappears

    //nuku walk to center
    .walkHero('#hero', { distance: 200, duration: 22, freezeContainer: true, parallax: true, skip_parallax_fg: true }, `${0}` ) // 20px/s
    

    //nuku raises arms in air

    .set(['#nuku-walking', '#nuku-profile'], { opacity: 0 }) //set hero to be invisible after NO pause
    .set("#nuku-turn-cycle", { backgroundPosition: "0% 0%", opacity: 1 })
    .to('#nuku-turn-cycle', { display: 'inline-block', paused: false, duration: 5 }) //set turn to be visible AND play after NO pause
    .add(turnCycleTl())
    .to('#sky', { backgroundColor: COLORS.SKY_NIGHT, duration: 20 }, '<')
    .to('#nuku-turn-cycle', { display: 'none' }) //set turn to be visible AND play after NO pause  
    .to('#nuku-turned', { display: 'inline-block' }) //set turn to be visible AND play after NO pause   
    .add(skyLightningTl())
    //nuku speaks
    // I want to leave Hawaiki
    //${gsap.getProperty(hero, "x") + 50} why we can't do this?

    .setSubtitleWithFade('#speech-slot-bl1', { subTitle: 4 - 1, x: 690, width: 415, height: 154, scaleY: SUB_ID4_SCALEY, skipFadeOut: true }, '+=4') // after short pause

    // nuku turns away again

    .to('#nuku-turned', { display: 'none' }, `+=${subtitleLength}`) //after prev subtitle shows for 12
    .to('#nuku-turn-cycle', { display: 'block', paused: false, duration: 10 }) //set turn to be visible AND play after NO pause

    .set("#nuku-turn-cycle", { backgroundPosition: "100% 0%" })
    .add(reverseTurnCycleTl())


    //nuku speaks
    // I must speak to Kupe
    //.set('#speech-slot-bl1', { x: 750 })
    .setSubtitleWithFade('#speech-slot-bl1', { subTitle: 5 - 1 })
    .to('#speech-slot-bl1 *', { scaleY: 1 }) // TODO: move to setSubtitleWithFade()


    .to('#nuku-turn-cycle', { display: 'none' }) //set turn to be invisible 
    .set('#nuku-walking', { opacity: 1 }) //re-set hero to be visible again

    
   
    // nuku begins to walk into scene 3
    //start walking as speech bubble finished disappearing
    .walkHero('#hero', { distance: 900, duration: 40, freezeContainer: false, parallax: true }) // 22.5px/s
    .add("heroWalkpanel2-3", '<')
    // .to("#container", { left:'+=220', duration: 14 }, 'heroWalkpanel2-3')

    //SHOW NARRATION BOX HERE 
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 6 - 1, x: 0, left: 750, width: 470 }, `heroWalkpanel2-3`) //show after short pause

    //SHOW NEXT NARRATION BOX HERE
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 7 - 1, x: 0, top: 'unset', bottom: '3.5%', left: 871, width: 615 }, `heroWalkpanel2-3+=${subtitleLength}`)    
    
    //waterfall out
    .call(toggleSoundPlaying, [soundNames.waterfall])  

export { panel2Tl }