import { registerWalkHero, bendCycleTl } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade, registerThoughtBubble } from '../../../subtitles/subtitleEffects';


registerSetSubtitleWithFade();
registerThoughtBubble();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3 = document.querySelector('#scene3');
const $waka = '#s3-waka'
const WAKA_CREW = [$waka];

const CONTAINER_OFFSET = 5400;
const WALK_DIST_1 = 2020;
const WALK_DUR_1 = 100;


const panel2Tl_A = () => gsap.timeline({ id: "scene-3a-panel-2-beach", paused: false, smoothChildTiming: true })

    .call(console.log, ["scene3a setup start"])
    .add('s3ap2_setup')

    .set('#s3-nuku-side-windy-hair', { opacity: 0 }, '<70')
    .add(bendCycleTl('#s3-boat-nuku-bend-cycle'), '<')
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 42 - 1, x: CONTAINER_OFFSET + 300, y: 100, width: 400 }, '<')


    .thoughtBubble('#thought-bubble-container', { subTitle: 43 - 1, x: 5587, y: 260, width: 400, display: 'block' }, '<40')

    // Panel finished
    .call(console.log, ["s3ap2 finished"], '>5');
    

const panel2Tl_BoatMove = () => gsap.timeline({ id: "scene-3a-panel-2-boatmove", paused: false, smoothChildTiming: true })
    .to(WAKA_CREW, { rotation: -4, duration: 5, repeat: 33, yoyo: true })
    .walkHero(WAKA_CREW, { distance: WALK_DIST_1, duration: WALK_DUR_1, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: true, idPrefix: 's3-' }, '<') // 20.2px/s waka
    .to($scene3, { x: `+=${-WALK_DIST_1 * 1}`, duration: WALK_DUR_1, ease: 'none' }, '<');


const panel2Tl = () => gsap.timeline({ id: "s3ap2_master", paused: false, smoothChildTiming: true })
    .add(panel2Tl_A(), 's3ap2_master')
    .add(panel2Tl_BoatMove(), 's3ap2_master');

export { panel2Tl }