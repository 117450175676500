import { registerWalkHero, nukuRaiseShootsTl, nukuLowerShootsTl } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { toggleSoundPlaying, soundNames } from '../../../audio/sounds';
import { panel3ContainerX } from '../../scene3a/calcs';
import { P1_NUMS } from '../calcs';


registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3b = document.querySelector('#scene3b');

const CONTAINER_OFFSET = -panel3ContainerX();
const QUICK = 0.1;
const LHS = 2960;


const panel1Tl_A = () => gsap.timeline({ id: "scene-3b-panel-1-shore", paused: false, smoothChildTiming: true })

    .call(console.log, ["scene3b setup start"])
    .add('s3bp1_setup')

    // === Music and SFX ===
    // Note: No new music. Scene 3b music simply continues from Scene 3a
    .call(toggleSoundPlaying, [soundNames.waterfall1])
    .call(toggleSoundPlaying, [soundNames.walking])
    .call(toggleSoundPlaying, [soundNames.moana], '<')
    .call(toggleSoundPlaying, [soundNames.manu], '<')


    // Position & size assets
    .set('#s3b-background', { x: LHS, y: -50, scale: 1.0 }, 's3bp1_setup')
    .set('#s3b-rimu', { x: LHS, y: -80, scale: 1.0 }, 's3bp1_setup')
    .set($scene3b, { display: 'block', x: CONTAINER_OFFSET - LHS, y: 50, scale: 1 }, "s3bp1_setup") // show scene3b content
    // .set($waka, { x: -1350, rotation: -14 }, "<")
    .set('#s3b-hero', { x: LHS - 300, y: -100, scale: 0.85 }, "<") // scale boat hero imgs


    // Show scene 3b
    .fromTo($scene3b, { opacity: 0 }, { opacity: 1, duration: 20 }, 's3bp1_setup')
    // Hide scene 3a
    .fromTo('#scene3', { opacity: 1 }, { opacity: 0, duration: QUICK }) // hide scene3a content
    .to([
        '#bottom-narration-slot-4',
        '#thought-bubble-container',
        '#thought-bubble-container-topleft'
    ], { display: 'none', duration: QUICK }, "<") // hide subtitles

    // Snap plants into position
    .from('#s3b-plants-foreground', { y: 150, x: -80, duration: 4, ease: 'back' }, "s3bp1_setup+=10")
    
    .walkHero('#s3b-hero', {
        distance: P1_NUMS.WALK_DIST_1,
        duration: P1_NUMS.WALK_DUR_1,
        walkWithShoot: true,
        freezeContainer: true,
        parallax: false,
        idPrefix: 's3b-',
    }, 's3bp1_setup+=10') // 6.6px/s


    .add( nukuRaiseShootsTl('#s3b-nuku-walking-shoot', 10) )
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 47 - 1, x: CONTAINER_OFFSET + 400, y: 50, width: 400 }, '>-10')
    .add( nukuLowerShootsTl('#s3b-nuku-walking-shoot', 10), '>-10' )

    // Panel finished
    .call(console.log, ["s3bp1 finished"], '>');


const panel1Tl = () => gsap.timeline({ id: "s3bp1_master", paused: false, smoothChildTiming: true })
    .add( panel1Tl_A(), 's3bp1_master' );

export { panel1Tl }