import { registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { panel3ContainerX } from '../../scene3a/calcs'; // TODO: move to scene3b calcs
import { toggleSoundPlaying, soundNames } from '../../../audio/sounds';
import { P3_NUMS } from '../calcs';

registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3b = document.querySelector('#scene3b');

const CONTAINER_OFFSET = -panel3ContainerX() + 1000;
const QUICK = 0.1;
const FADE_DUR = 30;


const panel3Tl_A = () => gsap.timeline({ id: "scene-3b-panel-3", paused: false, smoothChildTiming: true })

    .add('s3bp3')

    .walkHero('#s3b-hero', {
        distance: P3_NUMS.WALK_DIST_1,
        duration: P3_NUMS.WALK_DUR_1,
        walkWithShoot: true,
        freezeContainer: false,
        parallax: 1.0,
        idPrefix: 's3b-',
    }) // 10px/s

    .walkHero('#s3b-hero', {
        distance: P3_NUMS.WALK_DIST_2,
        duration: P3_NUMS.WALK_DUR_2,
        walkWithShoot: true,
        freezeContainer: true,
        parallax: false,
        idPrefix: 's3b-',
    }) // 10px/s

    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 49 - 1, x: CONTAINER_OFFSET + P3_NUMS.WALK_DIST_1 + 420, y: 50, width: 400 }, '>-70')

    // Panel finished
    .call(console.log, ["s3bp3 finished"], '>');


const creditsTl = () => gsap.timeline({ id: "credits", paused: false, smoothChildTiming: true })
    .add('credits')

    // Set position
    .set('#end-credits-container', { y: 1000 })

    // Fade to black
    .to('#end-credits', { duration: QUICK, display: 'block' })
    .to('#scene3b', { opacity: 0, duration: FADE_DUR })
    .fromTo('#end-credits', { opacity: 0 }, { opacity: 1, duration: FADE_DUR }, '<')

    // Play credits music & pause scene 3 music+SFX
    .call(() => {
        toggleSoundPlaying(soundNames.scene3bBackground);
        toggleSoundPlaying(soundNames.creditsBackground);
    }, [], `>-${FADE_DUR * .8}`)
    .call(toggleSoundPlaying, [soundNames.taiAo], '<')
    .call(toggleSoundPlaying, [soundNames.waterfall1], '<')
    .call(toggleSoundPlaying, [soundNames.walking], '<')

    // Scroll credits upwards
    .to('#end-credits-container', { y: -1150, duration: 250 }, `<`)
    ;


const panel3Tl = () => gsap.timeline({ id: "s3bp3_master", paused: false, smoothChildTiming: true })
    .add( panel3Tl_A(), '<' )
    .add( creditsTl(), `>-${FADE_DUR + 2}` );

export { panel3Tl }