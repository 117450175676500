import { parallaxTl, registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade, fadeOutSubTitle, registerThoughtBubble } from '../../../subtitles/subtitleEffects';
import { toggleSoundPlaying, soundNames } from '../../../audio/sounds';
import { subtitleLength, subtitleFadeOutDelay } from '../../../subtitles/subtitleConstants';
import { COLORS } from '../../../animation/styleConstants';

registerSetSubtitleWithFade();
registerThoughtBubble();
registerWalkHero();

const hero = document.getElementById('hero');
const container = document.getElementById('container');


const panel5Tl = () => gsap.timeline({ id: "panel-5-walk-to-beach", paused: false, smoothChildTiming: true })
    .call(console.log, ["p5"])
    .call(toggleSoundPlaying, [soundNames.taiAo])
    .call(toggleSoundPlaying, [soundNames.moana])
    // .set('#bottom-narration-slot-1', { scale:0.5, top:'unset',bottom:'27.5%', width: 462, left:3314, opacity:0 }) //set narration box 1 - small version
    // .set('#bottom-narration-slot-2', { scale:0.5, top:'unset', bottom:'27.5%', width: 462, left:3549, opacity:0 }) //set narration box 2 - small version
    
    //nuku continues walking from panel 4 into panel 5, he walks across screen towards beach AND ZOOM OUT

    .fromTo(container, { scale: 2 }, { scale: 1, ease: ExpoScaleEase.config(2, 1), duration: 20 }, 0)
    .walkHero('#hero', { distance: 700, duration: 19, freezeContainer: true, parallax: true }, '-=19') // 36.8px/s
    .add("S1P5_talking")
    .to(container, { x: '-=50', duration: 18 }, '-=18')
    .to(hero, { bottom: '-8%', duration: 17 }, '-=17')
    .to(hero, { scale: 0.3, duration: 17}, '<') //, ease: ExpoScaleEase.config(1, 2) 
    // .fromTo('#putatara', { scale: 0.3 }, { scale: 0.5, duration: 8, ease: ExpoScaleEase.config(0.3, 0.5) }, '<')
     //scale  putatara appropriately
     .to('#putatara', { scaleX: .6, scaleY: .6, duration: 17}, '-=17') //, ease: ExpoScaleEase.config(1, 2) 
    .to('#sky', { backgroundColor: COLORS.SKY_DAY, duration: 16 }, '-=16')

    //add narration boxes

    //narration box 1 appears
    .setSubtitleWithFade('#bottom-narration-slot-1', { subTitle: 13 - 1, skipFadeOut: true, scale: 1, top: 'unset', bottom: '3.5%', width: 'unset', height: 115, left: 3932, x: '-100%' })

    //narration box 2 appears
    //after 12s pause
    .setSubtitleWithFade('#bottom-narration-slot-2', { subTitle: 14 - 1, skipFadeOut: true, scale: 1, top: 'unset', bottom: '3.5%', width: 'unset', height: 115, left: 3930 })

    // //thought bubble box 2 appears
    .thoughtBubble('#thought-bubble-container', { subTitle: 15 - 1, skipFadeOut: true, width: 400, height: 134, display: 'block' })

    //start disappearing text --

    .add(fadeOutSubTitle('#bottom-narration-slot-2', { subTitle: 14 - 1 }))
    .add(fadeOutSubTitle('#bottom-narration-slot-1', { subTitle: 13 - 1 }), `+=${subtitleFadeOutDelay}`)
    .add(fadeOutSubTitle('#thought-bubble-container', { subTitle: 15 - 1 }), `+=${subtitleFadeOutDelay}`)
    // .to('#bottom-narration-slot-2', { opacity: 0, duration: subtitleFadeOut }) //delay for time to read speech bubble
    // .to('#bottom-narration-slot-1', { opacity: 0, duration: subtitleFadeOut }, `+=${subtitleFadeOutDelay}`)    
    // .to('#thought-bubble-container', { opacity: 0, duration: subtitleFadeOut },`+=${subtitleFadeOutDelay}` ) 

    .add(parallaxTl(null, 39, 1, true) , "S1P5_talking")
    ;
    
    




export { panel5Tl }