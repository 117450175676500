import { reverseTurnCycleTl, dancingChildrenShadowsTl, rockTaniwhaTl, skyLightningTl } from '../../../animation/animationEffect';
import { soundNames, toggleSoundPlaying } from '../../../audio/sounds';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';

registerSetSubtitleWithFade();

const $scene = document.querySelector('.scene');
const $taniwha = document.querySelectorAll('.taniwha');

const BACKGROUND_SEA_POSITION = 5570;
const TANIWHA_ANIM_REPEATS = 88;
const TANIWHA_ANIM_DURATION = 2;


const panel3Tl_AnimateTaniwha = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    // Place & show taniwhas to the far right
    .set('#taniwha_brown', { x: 7300, y: 460, opacity: 1 })
    .set('#taniwha_purple', { x: 7000, y: 400, opacity: 1 })
    .set('#taniwha_green', { x: 6800, y: 600, opacity: 1 })
    .set('#taniwha_orange', { x: 7150, y: 550, rotate: -17, rotateY: 180, opacity: 1 })

    // Animate orange taniwhas head/arms back & forward (head left & right, arms down to horizontal)
    .add(rockTaniwhaTl(TANIWHA_ANIM_DURATION, TANIWHA_ANIM_REPEATS), '<')


const panel3Tl_A = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    // Face nuku to right (reverse turn cycle)
    .to('#boat-nuku-turned', { display: 'none' }) //set turn to be visible AND play after NO pause   
    .to('#boat-nuku-turn-cycle', { display: 'block', opacity: 1 }) //set turn to be visible AND play after NO pause  
    .add(reverseTurnCycleTl('#boat-nuku-turn-cycle'))
    .set('#boat-nuku-turn-cycle', { opacity: 0 })
    .set('#nuku-side-windy-hair', { opacity: 1 })

    // Camera has panned right and zoomed out to show Taniwha swimming towards Boat
    .to($scene, { scale: 1, x: '-=900', duration: 5, ease: ExpoScaleEase.config(1, 2)}, '<6')
    .to($taniwha, { x: '-=800', duration: 45, stagger: 0.5 }, '+=1')

    // Taniwha then proceed to dive under the waves.
    .to($taniwha, { y: '+=250', duration: 10, stagger: 0.5, ease: "power1.inOut", id: 's2p3_dive_under_waves' }, '>-15')
    .add( skyLightningTl('#sky', 5), '<' )
    .call(toggleSoundPlaying, [ soundNames.thunder, true ], '<')

    // Open net?
    .fromTo('#waka-net', { scale: 0, opacity: 0 }, { scale: 0.6, opacity: 1, duration: 5 }, '<')

    // Camera then pans back left and zooms back in towards boat, and Taniwha re-emerge in their correct positions.
    .to($scene, { scale: 3, x: '+=1450', duration: 5, ease: ExpoScaleEase.config(1, 2)}, 's2p3_dive_under_waves-=2')
    .to($taniwha, { x: '-=570', stagger: 0.5, ease: "power1.inOut", duration: 4 }, '-=2')
    .to('#taniwha_green, #taniwha_purple', { scale: 0.5 })
    .to('#taniwha_green', { zIndex: 23 }, `<`)
    .to('#taniwha_orange', { x: '-=200', y: '-=300', duration: 6 }, `<`)
    .to('#taniwha_purple', { x: '-=95', y: '-=260', duration: 6 }, `<`)
    .to('#taniwha_green', { x: '+=63', y: '-=460', duration: 6 }, `<`);

const panel3Tl_B = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    // Narator
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 24 - 1, width: 700, x: '-=170', y: 0 }, '1')

    // Child 1 speechBubble
    .setSubtitleWithFade('#speech-slot-tr1', { subTitle: 25 - 1, x: BACKGROUND_SEA_POSITION - 290, y: 20, scale: 1.2, width: 381, height: 134, left: 0 })

    // Child 2 speechBubble
    .setSubtitleWithFade('#speech-slot-tr2', { subTitle: 26 - 1, x: BACKGROUND_SEA_POSITION - 200, y: 30, scale: 1.2, width: 365, height: 134, left: 0 })

    // TODO: The Kuri barking sound effect loops when the Kuri's speech bubble is present.

    // Kuri thoughtBubble
    .to('#thought-bubble-container .thought-bubble', { y: -20, width: 484, height: 133 })
    .thoughtBubble('#thought-bubble-container', { subTitle: 27 - 1, x: BACKGROUND_SEA_POSITION + 160, y: 50, scale: 1, height: 140, left: 0, display: 'block' })
    .to('#thought-bubble-container .thought-bubble', { y: 0 }, '>')

    .call(console.log, ["s2p3 finished"], '>');

const moveBgTl = () => gsap.timeline({ paused: false, smoothChildTiming: true });
    // move bg
    // .to($scene, { transformOrigin: `${BACKGROUND_SEA_POSITION - 1000}px 520px`, }, 30)

const panel3Tl = () => gsap.timeline({ id: "s2p3", paused: false, smoothChildTiming: true })
    .call(console.log, ["s2p3"])

    .add(panel3Tl_A(), 's2p3')
    .addLabel('s2p3_B', '>')
    .add(panel3Tl_AnimateTaniwha(), 's2p3')
    .add(moveBgTl(), 's2p3')

    .add(panel3Tl_B(), 's2p3_B')
    .add(dancingChildrenShadowsTl(), 's2p3_B+=0.5');

export { panel3Tl }