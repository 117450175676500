import { scene1Tl, scene1TlSetup } from './timelines/scene1/scene1';
import { scene2Tl, scene2TlSetup } from './timelines/scene2/scene2';
import { scene3aTl, scene3aTlSetup } from './timelines/scene3a/scene3a';
import { scene3bTl, scene3bTlSetup } from './timelines/scene3b/scene3b';
import { initLanguageButtons, initControls, startAnimation } from './controls/controls';
import { controlTimelineByScroll } from './controls/scrollerController';
import { languageCodes, toggleLanguage } from './subtitles/language';
import { animationSizes } from './animation/animationSizes';


console.log('index');
const params = new URLSearchParams(window.location.search);
const devMode = params.get('devmode') != null;
const devMode_jump = params.get('jump');
const start = (params.get('start') || '0' ).split(',').map(Number); // array of start times (hit these times at load)

initLanguageButtons();
const lang = params.get('lang');
if (lang == languageCodes.ENGLISH) {
    toggleLanguage();
}

const autoplay = params.get('autoplay') || devMode;
const checkAutoPlay = () => {
    if (autoplay) {
        startAnimation();
    }
};

const setMasterScale = () => {
    const sizes = animationSizes();

    const root = document.documentElement;
    if (!(sizes && sizes.window.height && root && root.style && root.style.setProperty)) return;

    root.style.setProperty('--screen-ratio', sizes.window.ratio);
    root.style.setProperty('--mobile-scale', sizes.animation.mobileScale);
    root.style.setProperty('--desktop-scale', sizes.animation.desktopScale);
    root.style.setProperty('--anim-height-px', sizes.animation.height + 'px');
    root.style.setProperty('--anim-width-px', sizes.animation.width + 'px');
    root.style.setProperty('--full-width-scale', sizes.animation.fullWidthScale);
    root.style.setProperty('--extra-width', sizes.animation.extraWidth + 'px');
}

window.onunload = () => {
    window.scrollTo(0, 0); // FORCE browser to start of animation BEFORE page unloaded, so that NEXT reload the app will not have weird janky errors.
}

let roottl;
window.onload = () => {
    console.log('loaded');

    // scale animation depending on browser height
    setMasterScale();
    window.onresize = setMasterScale;

    gsap.defaults({ overwrite: "auto" });
    initControls();

    scene1TlSetup().play();

    roottl = gsap.timeline({ id: "root", paused: true, smoothChildTiming: true });
    roottl
        .add(scene1TlSetup())
        .add(scene1Tl())
        .add(scene2TlSetup())
        .add(scene2Tl())
        .add(scene3aTlSetup())
        .add(scene3aTl())
        .add(scene3bTlSetup())
        .add(scene3bTl())
    window.roottl = roottl;

    if (devMode) {
        roottl.play();
        const devOpts = { animation: roottl, loop: false, timeScale: 5 };
        console.log('devmode:', devOpts);
        GSDevTools.create(devOpts);
        checkAutoPlay();

        if (devMode_jump) {
            devMode_jump.split(',').forEach( (jump, jumpNum) =>
                setTimeout(() => {
                    document.querySelector(`.animation-list [value=${jump}]`).selected = true;
                    document.querySelector('.animation-list').dispatchEvent(new CustomEvent('change'));
                }, 300 * (jumpNum+1))
            );
        }
   
    } else {
        controlTimelineByScroll(roottl, start);
        checkAutoPlay();
    }
}
