import { registerWalkHero, reverseBendCycleTl, fallingFeatherTl } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { NO_FILTER_HSV, filterHSV } from '../../../animation/domEffects';
import { wideShotScale, extremeWideShotScale, panel3ContainerX } from '../calcs';


registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3 = document.querySelector('#scene3');
const $waka = '#s3-waka'
const WAKA_CREW = [$waka];
const QUICK = 0.1;

const BOATROCK_DUR = 5;
const WALK_DIST_1 = 5350;
const WALK_DUR_1 = 353;
const ZOOM_WS = 1.6;

const WALK_DIST_2 = 4000;
const WALK_DUR_2 = 230;
const ZOOM_DUR_2 = 30;
const ZOOM_2_ORIGIN_DUR = 60;
const TRANSFORM_ORIGIN_WITH_SCENE_X = -2500;
const ZOOM_EWS = extremeWideShotScale();

const CONTAINER_OFFSET = 5400;
const STORM_DURATION = 20;


const panel3Tl_A = () => gsap.timeline({ id: "scene-3a-panel-3-beach", paused: false, smoothChildTiming: true })

    .call(console.log, ["scene3a setup start"])
    .add('s3ap3_setup')

    .add(reverseBendCycleTl('#s3-boat-nuku-bend-cycle'), '<')
    .set('#s3-boat-nuku-bend-cycle', { opacity: 0 })
    .set('#s3-nuku-side-windy-hair', { opacity: 1 }, '<')


    .add(panel3Tl_FallingFeather(), '<140')
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 44 - 1, x: CONTAINER_OFFSET + 300, y: 100, width: 400 }, '<')

    // .setSubtitleWithFade('#speech-slot-bl1-TRM-only', { subTitle: 45 - 1, x: CONTAINER_OFFSET + 380, y: 200, width: 364, scale: 1.2 }, '<50')
    .thoughtBubble('#thought-bubble-container-TRM-only', { subTitle: 45 - 1, x: 1590, y: 180, width: 400 }, '<50')
    .to('.thought-bubble', { x: '+=250', y: '+=50' }, '<')

    // Make scene go to daytime
    .to([$waka, '.s3a-bg-wave'], { filter: NO_FILTER_HSV, duration: STORM_DURATION }, `<`)

    // Panel finished
    .call(console.log, ["s3ap3 finished"], '>5');


const containerX = panel3ContainerX();
const SUN_SHIFT_X = 700;
const SUN_SHIFT_Y = 300;
console.log('s3a EWS containerX', containerX);

let transOrigin = { top: 65, left: 50 };
const setSceneTransformOrigin = gsap.quickSetter($scene3, "transformOrigin");

const panel3Tl_BoatMove = () => gsap.timeline({ id: "scene-3a-panel-3-boatmove", paused: false, smoothChildTiming: true })
    .to(WAKA_CREW, { rotation: -4, duration: BOATROCK_DUR, repeat: (WALK_DUR_1) / BOATROCK_DUR, yoyo: true })
    .walkHero(WAKA_CREW, { distance: WALK_DIST_1 * (1/ZOOM_WS) * 10/WALK_DUR_1, duration: 10, sideWindyHair: false, walkLegs: false, freezeContainer: true, parallax: true, idPrefix: 's3-' }, '<') // 9.5px/s waka
    .to($scene3, { x: `+=${-WALK_DIST_2 * 1}`, duration: WALK_DUR_2, ease: 'none' }, '<')
    
    .to('#s3a-sky-background', { x: `-=${SUN_SHIFT_X}`, duration: WALK_DUR_1 }, '<')
    .to('#s3a-sun-eclipse-1, #s3a-sun-eclipse-2', { x: `-=${SUN_SHIFT_X}`, y: `+=${SUN_SHIFT_Y}`, duration: WALK_DUR_1 }, '<')

    .to($scene3, { transformOrigin: `${transOrigin.top}% ${transOrigin.left}%`, duration: QUICK }, '<')
    .to($scene3, { duration: 40, scale: ZOOM_WS, y: 0 }, `<${QUICK}`)
    .walkHero(WAKA_CREW, { distance: WALK_DIST_1 * (1/ZOOM_WS) * (WALK_DUR_1-10)/WALK_DUR_1, duration: WALK_DUR_1, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: true, idPrefix: 's3-' }, '<10') // 9.2px/s waka

    // Final extreme wide-shot zoom
    .to(transOrigin, {
        top: 98, //42.89,
        left: 91.52,
        duration: QUICK,
        onUpdate: () => setSceneTransformOrigin( `${transOrigin.top}% ${transOrigin.left}%` )
    }, `>-100`)
    //-1910px, 220
    //-2500px, 192.664
    .to($scene3, { x: TRANSFORM_ORIGIN_WITH_SCENE_X, y: 192, duration: QUICK, onUpdate: () => {console.log('s3a quick2')} }, '<')
    .to($scene3, {
        y: 50,
        scale: ZOOM_EWS,
        duration: ZOOM_DUR_2,
    }, `<${QUICK}`)
    .to($container, { x: containerX, duration: ZOOM_DUR_2 }, '<');
    

const panel3Tl_FallingFeather = () => gsap.timeline({ id: "scene-3a-panel-3-feather", paused: false, smoothChildTiming: true })
    .add('feather_drop')
    .fromTo('#s3-falling-feather', { opacity: 0 }, { opacity: 1, duration: 7 })
    .add(fallingFeatherTl('#s3-falling-feather', 0))
    .to('#s3-falling-feather', { x: '+=200', scale: 1.5, duration: 5, ease: Linear.easeIn }, 'feather_drop+=3.5')
    .to('#s3-falling-feather', { x: '+=200', y: '-=200', scale: 2, duration: 10, ease: Linear.easeIn }, 'feather_drop+=8.5')
    .to('#s3-falling-feather', { x: '+=1200', scale: 4, duration: 70, ease: Linear.easeIn }, 'feather_drop+=18.5')
    .to('#s3-falling-feather', { y: '-=125', duration: 45 }, 'feather_drop+=18.5').yoyo(true)
    .to('#s3-falling-feather', { x: '+=400', y: '+=80', duration: 20, opacity: 0, ease: Linear.easeOut });
    

const panel3Tl = () => gsap.timeline({ id: "s3ap3_master", paused: false, smoothChildTiming: true })
    .add(panel3Tl_A(), 's3ap3_master')
    .add(panel3Tl_BoatMove(), 's3ap3_master')

export { panel3Tl }