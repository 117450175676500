import subTitles from './subtitles';
import { subtitleLength, subtitleFadeIn, subtitleFadeOut, subtitleFadeOutDelay } from './subtitleConstants';
import { getVoiceOverFile, playVoiceOver } from '../audio/sounds';
import { languageCodes } from './language';

const rawSubToHtml = (text) => text.split('\n').join("<br/>");

const setSubtitleText = (tl, target, text, position = 0, duration) => {
    if (Array.isArray( text )) {
        // text is array of subs, not single sub. stagger them
        text.forEach( (sub, subIndex) => {
            // tl.call(() => { console.log( 'subtitle:', `pt${subIndex}`, sub )});
            tl.to(target, { text: rawSubToHtml(sub) }, position + subIndex * duration, );
        });

    } else {
        // single sub
        // tl.call(() => { console.log( 'subtitle:', text ) });
        tl.to(target, { text: rawSubToHtml(text) }, position);
    }
}

const setSubtitleVoice = (tl, voiceBtn, subTitleIndex, position = '<') => {
    const subTitle = subTitles[subTitleIndex];//consider some kind of next()
    if (!voiceBtn || !voiceBtn[0]) {
        console.log('Error: No voice btn?');
        console.log(subTitle);
        // debugger;
        return;
    }

    if (subTitle.voice) {
        // Sanity checks: check voiceOver configured properly
        for (const lang in languageCodes) {
            const langCode = languageCodes[lang];
            // if (!getVoiceOverFile(subTitle.voice, langCode)) alert(`${lang} VO files for sub id#${subTitle.id} not configured properly! check sounds.js`);
        }

        tl.call(() => {
            console.log(`Enabling ${window.lang} VO files for sub id#${subTitle.id}`, subTitle)
            voiceBtn[0].onclick = () => {
                console.log(`Playing ${window.lang} VO files for sub id#${subTitle.id}`, subTitle)
                playVoiceOver(subTitle.voice, voiceBtn);
            };
        }, null, position);

        tl.set(voiceBtn, { opacity: 1 });

    } else {
        tl.set(voiceBtn, { opacity: 0 });
    }
}

const setTargetChildrenProps = (tl, target, config) => {
    let children = Array.from(target.children);
    tl.to(children, config);
};

// Position/size sub, or set other gsap props
const positionSubtitle = (tl, target, config) => {
    const ignoreProps = [
        'subTitle', 'duration', 'ignorePositioning', 'skipFadeOut',
        'childProps', 'childScale', 'childScaleX', 'childScaleY',
    ];
    for (const property in config) {
        if (!ignoreProps.includes(property)) tl.set(target, {[property]: config[property] });
    }

    if (config.childScale || config.scale)
        setTargetChildrenProps(tl, target, { scale: config.childScale || (1.0 / config.scale) });
    if (config.childScaleX || config.scaleX)
        setTargetChildrenProps(tl, target, { scaleX: config.childScaleX || (1.0 / config.scaleX) });
    if (config.childScaleY || config.scaleY)
        setTargetChildrenProps(tl, target, { scaleY: config.childScaleY || (1.0 / config.scaleY) });
}

const display_visible = 'flex';

const getSubTitleVoiceBtn = (targets) => {
    const target = targets[0];
    let children = Array.from(target.children);
    children = children.concat( Array.from(children[0].children) );
    const voiceBtn = children.filter(x => x.classList.contains('voiceover-btn'));
    return voiceBtn;
}

const displaySubTitle = (tl, targets, config) => {
    targets = typeof targets == 'string' ? document.querySelectorAll(targets) : targets;
    const voiceBtn = getSubTitleVoiceBtn(targets);

    tl.fromTo(targets, { display: 'none' }, { display: display_visible })
    if (voiceBtn) {
        setSubtitleVoice(tl, voiceBtn, config.subTitle, '<');
    }
}

const fadeOutSubTitle = (targets, config) => {
    targets = typeof targets == 'string' ? document.querySelectorAll(targets) : targets;
    const voiceBtn = getSubTitleVoiceBtn(targets);

    const tl = gsap.timeline();
    tl.to(targets, { opacity: 0, duration: subtitleFadeOut });
    if (voiceBtn) {
        setSubtitleVoice(tl, voiceBtn, config.subTitle, '>');
    }
    tl.fromTo(targets, { display: display_visible }, { display: 'none' });
    return tl;
}

const registerSetSubtitleWithFade = () => gsap.registerEffect({
    name: "setSubtitleWithFade",
    effect: (targets, config) => {
        const subTitle = subTitles[config.subTitle];//consider some kind of next()
        if (!subTitle) {
            const errMsg = `subTitle ${config.subTitle} not found!`;
            alert(errMsg);
            return;
        }
        
        const target = targets[0];
        const children = Array.from(target.children);
        const duration = config.duration || subtitleLength;
        
        const hasClass = (el, cls) => el.classList.contains(cls);
        const isVoBtn = (el) => hasClass(el, 'voiceover-btn');
        const englishDiv = children.filter(x => hasClass(x, 'english') && !isVoBtn(x));
        const teReoDiv = children.filter(x => hasClass(x, 'teReo') && !isVoBtn(x));

        const tl = gsap.timeline();

        if (!config.ignorePositioning) positionSubtitle(tl, target, config);
        if (!config.skipDisplay) displaySubTitle(tl, targets, config);
        if (config.childProps) setTargetChildrenProps(tl, target, config.childProps);

        tl.call(() => { console.log(`sub#${config.subTitle}`) });
        setSubtitleText(tl, englishDiv, subTitle.english, 0, duration);
        setSubtitleText(tl, teReoDiv, subTitle.teReo, 0, duration);

        tl.to(targets, { opacity: 1, duration: subtitleFadeIn }, 0);

        if (!config.skipFadeOut) tl.add(fadeOutSubTitle(targets, config), `+=${duration}`);

        return tl;
    },
    extendTimeline: true
});

const registerThoughtBubble = () => gsap.registerEffect({
    name: "thoughtBubble",
    effect: (targets, config) => {        
        const target = targets[0];
        const $thoughtBubble = target.querySelector('.thought-bubble');
        const innerConfig = { ...config, ignorePositioning: true, skipFadeOut: true }

        const tl = gsap.timeline();
        positionSubtitle(tl, target, config);

        if (!config.skipDisplay) {
            tl.set(targets, { display: display_visible });
            tl.to(target.querySelectorAll('.bubble-round'), { opacity: 1, duration: 3, stagger: 1 })
        }

        tl.setSubtitleWithFade($thoughtBubble, innerConfig, `+=0`) //after 12s pause
        tl.to([targets, $thoughtBubble], { opacity: 1, duration: subtitleFadeIn }, '<')

        if (!config.skipFadeOut) {
            const duration = config.duration || subtitleLength;
            tl.add(fadeOutSubTitle([$thoughtBubble], config), `+=${duration}`);
            tl.to(targets, { opacity: 0, duration: subtitleFadeOut },`<` )
            tl.set(targets, { display: 'none' });
        }

        return tl;
    },
    extendTimeline: true
});

export { registerSetSubtitleWithFade, registerThoughtBubble, fadeOutSubTitle }