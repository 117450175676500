import { skyLightningTl, manuFlapWingsTl, dancingChildrenShadowsTl, registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { COLORS } from '../../../animation/styleConstants';
import { NO_FILTER_HSV, filterHSV } from '../../../animation/domEffects';


registerSetSubtitleWithFade();
registerWalkHero();

const STORM_DURATION = 20;
const BACKGROUND_SEA_POSITION = 5570;
const WAKA_CREW = '#waka';


const panel6Tl_A = () => gsap.timeline({ id: 's2p6_A', paused: false, smoothChildTiming: true })
.add('s2p1_setup')

    // Camera zoom out & move right a bit
    .to('.scene', { scale: 1, x: '-=500', duration: 5, ease: ExpoScaleEase.config(1, 2)})

    // // Move bg clouds left
    // .to('#clouds', { scale: 1, x: '-=700', duration: 5, ease: ExpoScaleEase.config(1, 2)})

    // Lightning
    .add(skyLightningTl(), '<')

    // Change sky colours
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_75, duration: STORM_DURATION*1/10 }, `s2p6_A+=${STORM_DURATION*5/10}`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_50, duration: STORM_DURATION*1/10 }, `s2p6_A+=${STORM_DURATION*6/10}`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY_TO_NIGHT_STORM_25, duration: STORM_DURATION*1/10 }, `s2p6_A+=${STORM_DURATION*7/10}`)
    .to('#sky', { backgroundColor: COLORS.SKY_DAY, duration: STORM_DURATION*1/10 }, `s2p6_A+=${STORM_DURATION*8/10}`)

    // Make scene go to daytime
    .to('#waka, #clouds, .bg-wave', { filter: NO_FILTER_HSV, duration: STORM_DURATION }, `s2p6_A+=${STORM_DURATION*5/10}`)
    .to('.s2-dark-clouds', { opacity: 0, duration: STORM_DURATION }, `s2p6_A+=${STORM_DURATION*5/10}`)

    // Narrator
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 39 - 1, x: BACKGROUND_SEA_POSITION - 345, y: 25, height: 'unset' })
 
    // Tamariki speechBubble
    .setSubtitleWithFade('#speech-slot-bl1', { subTitle: 40 - 1, x: BACKGROUND_SEA_POSITION - 40, y: 200, scale: 1, childScale: 0.9, width: 365, height: 134 })
    .to('#speech-slot-bl1 .english, #speech-slot-bl1 .teReo', { x: 36, y: -21 }, '<')

    // Fade to black
    .to('.scene', { opacity: 0, duration: 30 })
    .add(dancingChildrenShadowsTl('#dancing-children-shadows', 2), '<-45')
    ;
    

const panel6Tl = () => gsap.timeline({ id: "s2p6", paused: false, smoothChildTiming: true })
    .call(console.log, ["s2p6"])

    .add(panel6Tl_A(), 's2p6')
    // .add(rockTaniwhaTl(TANIWHA_ANIM_DURATION, TANIWHA_ANIM_REPEATS), 's2p6')

    // translate3d(6400px, 195px, 0px) scale(0.2)
    .add( manuFlapWingsTl('#s2-manu1', 100, 20, 6250, 165, 0, '+=0', '-=0', 0.4, false), '2')
    .add( manuFlapWingsTl('#s2-manu2', 100.7, 20, 6320, 195, 0, '+=0', '-=0', 0.5, false), '1')
    .add( manuFlapWingsTl('#s2-manu3', 101.3, 20, 6220, 225, 0, '+=0', '-=0', 0.53, false), '0')
    
    .to(WAKA_CREW, { rotation: -4, duration: 5, yoyo: true, repeat: 100/5 }, 0)
    .walkHero([WAKA_CREW, '#s2-manu1', '#s2-manu2', '#s2-manu3'], { distance: 1000, duration: 30, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: false }, '>-22') // 33.3px/s waka

    .call(console.log, ["s2p6 finished"], '>');

export { panel6Tl }