import { registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { toggleSoundPlaying, soundNames } from '../../../audio/sounds';
import { NO_FILTER_HSV, filterHSV } from '../../../animation/domEffects';
import { extremeWideShotScale } from '../calcs';


registerSetSubtitleWithFade();
registerWalkHero();

const $container = document.querySelector('#container');
const $scene3 = document.querySelector('#scene3');
const $waka = '#s3-waka'
const WAKA_CREW = [$waka];

const CONTAINER_OFFSET = 5400;
const QUICK = 0.1;
const NUKU_SCALE = 0.05;
const WALK_DIST_1 = 370;
const WALK_DUR_1 = 45;
const WALK_DIST_2 = 370;
const WALK_DUR_2 = 45;
const STORM_DURATION = 35;
const ZOOM_EWS = extremeWideShotScale();


const panel1Tl_A = () => gsap.timeline({ id: "scene-3a-panel-1-beach", paused: false, smoothChildTiming: true })

    .call(console.log, ["scene3a setup start"])
    .add('s3ap1_setup')

    // Scene 3 music
    .call(() => {
        toggleSoundPlaying(soundNames.scene2Background);
        toggleSoundPlaying(soundNames.scene3bBackground);
        console.log;
    })
    .call(toggleSoundPlaying, [soundNames.taiAo])
    .call(toggleSoundPlaying, [soundNames.manu])


    // Position & size assets
    .set('#s3a-sky-background', { x: 4850, y: -1000, scaleX: 1.45, scaleY: 6.3 }, 's3ap1_setup')
    .set($scene3, { display: 'block', x: 2130, y: 50, scale: ZOOM_EWS }, "s3ap1_setup") // show scene3 content
    .set($waka, { x: -1350, rotation: -14 }, "<")
    .set('#s3a-sun-eclipse-2', { scale: 0.7 })
    .set('#s3-waka-hero', { scale: NUKU_SCALE }, "<") // scale boat hero imgs
    .set('#s3a-clouds1', { x: 2020, y: -290, scale: 0.8 })
    .set('#s3a-clouds2', { x: 3000, y: -500, scale: 0.8 })
    .set('#s3a-clouds3', { x: 2420, y: -390, scale: 0.8 })
    .set('#s3a-dark-clouds1', { x: 4850, y: -260, scale: 0.8 })
    .set('#s3a-dark-clouds2', { x: 4300, y: -670, scale: 0.5 })
    .set('#s3a-dark-clouds3', { x: 3800, y: -682, scale: 0.5 })


    // Make scene go dark
    .fromTo([$waka], { filter: NO_FILTER_HSV }, { filter: filterHSV(0,40,1), duration: STORM_DURATION }, "s3ap1_setup+=20")
    .fromTo('#s3a-bg-wave-1', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,20,2), duration: STORM_DURATION }, "<")
    .fromTo('#s3a-bg-wave-2', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,25,2), duration: STORM_DURATION }, "<")
    .fromTo('#s3a-bg-wave-3', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,20,2), duration: STORM_DURATION }, "<")
    .fromTo('#s3a-bg-wave-4', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,8,2), duration: STORM_DURATION }, "<")
    .fromTo('#s3a-bg-wave-5', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,25,2), duration: STORM_DURATION }, "<")
    .fromTo('#s3a-bg-wave-6', { filter: NO_FILTER_HSV }, { filter: filterHSV(6,13,2), duration: STORM_DURATION }, "<")

    // Show scene 3a
    .fromTo($scene3, { opacity: 0 }, { opacity: 1, duration: 18 }, '2')

    .setSubtitleWithFade('#speech-slot-bl1', { subTitle: 41 - 1, x: CONTAINER_OFFSET + 100, y: 300, width: 366, height: 134, scale: 1.2 }, '<15')

    // Panel finished
    .call(console.log, ["s3ap1 finished"], '>');

const panel1Tl_BoatMove = () => gsap.timeline({ id: "scene-3a-panel-1-boatmove", paused: false, smoothChildTiming: true })
    // Pt1: zoomed-out move
    .add('s3p1_boat')
    .to(WAKA_CREW, { rotation: -4, duration: 5, yoyo: true, repeat: 17 }, '<')
    .walkHero(WAKA_CREW, { distance: WALK_DIST_1, duration: WALK_DUR_1, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: true, idPrefix: 's3-' }, '<') // 8.2px/s waka

    // Pt 2: zoom-in and move
    .to($scene3, { scale: 1, duration: WALK_DUR_2 }, `<${WALK_DUR_1}`)
    .to($scene3, { x: `+=${-WALK_DIST_2 * 1}`, duration: WALK_DUR_2, ease: 'none' }, '<')
    .walkHero(WAKA_CREW, { distance: WALK_DIST_2, duration: WALK_DUR_2, sideWindyHair: true, walkLegs: false, freezeContainer: true, parallax: true, idPrefix: 's3-' }, '<') // 8.2px/s waka


const panel1Tl = () => gsap.timeline({ id: "s3ap1_master", paused: false, smoothChildTiming: true })
    .add( panel1Tl_A(), 's3ap1_master' )
    .add( panel1Tl_BoatMove(), '<' )

export { panel1Tl }