import { introTl, panel1Tl, setupTl } from './panel1/panel1';
import { panel2Tl } from './panel2/panel2';
import { panel3Tl } from './panel3/panel3';
import { panel4Tl } from './panel4/panel4';
import { panel5Tl } from './panel5/panel5';
import { panel6Tl } from './panel6/panel6';
import { outroTl } from './outro/outroTl';
import { parallaxTl } from '../../animation/animationEffect';

const scene1Tl = () => gsap.timeline()
    .add(introTl())
    .add(panel1Tl())
    .add(panel2Tl())
    .add(panel3Tl())
    .add(panel4Tl())
    .add(panel5Tl())
    .add(panel6Tl())
    .add(outroTl())
    .call(() => console.log('s1 finished'))
    
    .add(parallaxTl(null, 203 - 73, 1, true) , 73)
    .add(parallaxTl(null, 326 - 263, 1, true) , 263)
    .add(parallaxTl(null, 351 - 346.5, 1, true) , 346.5)
    .add(parallaxTl(null, 427 - 356, 1, true) , 356)
    ;

const scene1TlSetup = setupTl;

export { scene1Tl, scene1TlSetup }