import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import * as soundNames from '../../../audio/soundNames';
import { toggleSoundPlaying } from '../../../audio/sounds';
import { subtitleLength } from '../../../subtitles/subtitleConstants';

registerSetSubtitleWithFade();

const container = document.getElementById('container');

const panel3Tl = () => gsap.timeline({ id: "panel-3-kupe-korero", paused: false, smoothChildTiming: true })
    .call(console.log, ["p3"])
    .call(toggleSoundPlaying, [soundNames.fire])
    .fromTo(container, { transformOrigin: `50% 50%` }, { transformOrigin: `1800px 40%` }) // the point on which to zoom/rotate, etc
    .fromTo(container, { scale: 1 }, { scale: 2, ease: ExpoScaleEase.config(1, 2), duration: 20 }, 0)
    .to('#speech-slot-bl1 .voiceover-btn', { bottom: '4px' }) //position the speech bubble button
    .to('#speech-slot-bl2 .voiceover-btn', { top: '-32px', left: '21px', bottom: 'unset', right: 'unset', transform: 'rotateY(180deg)' }) //position the speech bubble button
    .set(['#speech-slot-bl2 .english', '#speech-slot-bl2 .teReo'], { transform: 'rotateY(180deg)' }) //there is probably a smarter way to flip textbox than this double handling
    .setSubtitleWithFade('#speech-slot-bl1', { subTitle: 8 - 1, x: 0, y: 0, top: '17%', left: 1510, scale: .5, childScale: 1, width: 446, height: 164 }, '+=4')
    .setSubtitleWithFade('#speech-slot-bl2', { subTitle: 9 - 1, x: 0, y: 0, width: 410, height: 151, top: '32%', left: 1570, scale: 1, transform: 'scale(0.5) rotateY(180deg)' }, `+=${subtitleLength}`)
    // width: 410px;
    // height: 151px;
    // top: 32%;
    // left: 1570px;
    //unset speech bubble hacks
    .to('#speech-slot-bl1', { top: 'unset', left: 'unset', transform: 'unset', width: 'unset', height: 'unset', paddingLeft: 'unset', paddingBottom: 'unset' })
    .call(toggleSoundPlaying, [soundNames.fire])

    //Set where Putatara is for Panel 4/5
    .to('#putatara', { scaleX: .4, scaleY: .4, position:'fixed' },); 

export { panel3Tl }