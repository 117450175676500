import { panel1Tl } from './panel1/panel1';
import { panel2Tl } from './panel2/panel2';
import { panel3Tl } from './panel3/panel3';
import { cameraShift, SCENE3B_DUR } from './calcs';

const CAMERA_SHIFT = cameraShift();
const CAM_SHIFT_WAIT = 145;

const scene3bTl = () => gsap.timeline({ id: "scene-3b" })
    .add('s3b')

    .add(panel1Tl())
    .add(panel2Tl())
    .add(panel3Tl())

    // Shift camera left so that scene cutoff is not visible at end of anim
    .to('#scene3b, .subtitle-slots', { x: `+=${CAMERA_SHIFT}`, duration: SCENE3B_DUR-CAM_SHIFT_WAIT }, `s3b+=${CAM_SHIFT_WAIT}`)

    .call(() => console.log('s3b finished'));

const scene3bTlSetup = () => {};

export { scene3bTl, scene3bTlSetup }