const scene1_subtitles = [
    // s1p1
    {
      "id": 1,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Tūtaki mai ki a Nukutawhiti. I whānau mai, i tupu mai hoki i Hawaiki.",
      "english": "Meet Nukutawhiti. Born and raised in Hawaiki.",
      "voice": {
        "TRM": "S1/ID1 P1 TRM",
        "ENG": "S1/ID1 P1 ENG"
      }
    },
    {
      "id": 2,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "He kaiwhakatere waka, he kaitorotoro whenua,\n he tohunga, ka mutu, pena nō Ngāpuhi koe, he tupuna hoki ia nōu.",
      "english": "A navigator, an explorer, a knowledge holder and, if you’re Ngāpuhi, one of your celebrated tupuna.",
      "voice": {
        "TRM": "S1/ID2 P1 TRM",
        "ENG": "S1/ID2 P1 ENG"
      }
    },
    {
      "id": 3,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ko tāna mahi, he rapu mahi toa māna,\n oranga hoki mōna i tawhiti.",
      "english": "He seeks adventure and prosperity\n away from the shores of his homelands.",
      "voice": {
        "TRM": "S1/ID3 P1 TRM",
        "ENG": "S1/ID3 P1 ENG"
      }
    },

    // s1p2
    {
      "id": 4,
      "panel": 2,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Kia wehe atu au i Hawaiki nei,\n ki te rapu i Te Puna i te Ao Mārama.",
      "english": "I want to leave Hawaiki and\n rediscover Te Puna i te Ao Mārama.",
      "voice": {
        "TRM": "S1/ID4 P2 TRM",
        "ENG": "S1/ID4 P2 ENG"
      }
    },
    {
      "id": 5,
      "panel": 2,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Me kōrero au ki a Kupe!",
      "english": "I must speak to Kupe!",
      "voice": {
        "TRM": "S1/ID5 P2 TRM",
        "ENG": "S1/ID5 P2 ENG"
      }
    },
    {
      "id": 6,
      "panel": 2,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ehara i te mea, kei a Nukutawhiti nahe ngā\n mātauranga o te whakatere waka,\n haere moana hoki.",
      "english": "It was not only Nukutawhiti who possesses\n the knowledge of navigation and ocean\n voyaging.",
      "voice": {
        "TRM": "S1/ID6 P2 TRM",
        "ENG": "S1/ID6 P2 ENG"
      }
    },
    {
      "id": 7,
      "panel": 2,
      "character": "Narrator",
      "type": "narration",
      // "teReo": "Ko Kupe hoki tētahi, tana hungawai tāne. Kua tere kē mai\n a Kupe ki Aotearoa mā runga i tana waka hourua,\n a Matawhaorua, ka hoki anō ia ki Hawaiki.",
      "teReo": "KO KUPE HOKI TĒTAHI, TANA TUPUNA (TANA HUNGAWAI TĀNE RĀNEI).\n Kua tere kē mai a Kupe ki Aotearoa mā runga i tana\n waka hourua, a Matawhaorua, ka hoki anō ia ki Hawaiki.",
      // "english": "His father-in-law, Kupe sailed all the way to the distant\n Aotearoa on the waka hourua Matawhaorua,\n before returning to Hawaiki.",
      "english": "HIS ANCESTOR (OR HIS FATHER-IN-LAW), KUPE sailed all the way\n to the distant Aotearoa on the waka hourua Matawhaorua,\n before returning to Hawaiki.",
      "voice": {
        "TRM": "S1/ID7 P2 TRM",
        "ENG": "S1/ID7 P2 ENG"
      }
    },

    // s1p3
    {
      "id": 8,
      "panel": 3,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "E Kupe, e hiahia ana au kia kite i te ao.\n Ka nui taku hiahia kite i te whenua\n e kōrero nei koe. Māu au e tohutohu?",
      "english": "Kupe, I am hungry for adventure. I want\n to experience the land you speak of.\n Can you please help me?",
      "voice": {
        "TRM": "S1/ID8 P3 TRM",
        "ENG": "S1/ID8 P3 ENG"
      }
    },
    {
      "id": 9,
      "panel": 3,
      "character": "Kupe",
      "type": "speechBubble",
      "teReo": "Pai ana.\n Kua mōhio kē au ki tō whakaaro.\n Nō reira, mea nei te takoha māu.",
      "english": "Āe, tama.\n We knew this time would come.\n We have a gift for you, haere mai.",
      "voice": {
        "TRM": "S1/ID9 P3 TRM",
        "ENG": "S1/ID9 P3 ENG",
      }
    },

    // s1p4
    {
      "id": 10,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Kua mōhio kē hoki a Kupe, ko Aotearoa te wāhi tika\n mō ana uri, ka mutu, ko Nukutawhiti te tangata\n tika hei hautū i te waka hourua nei.",
      "english": "Kupe, knew Aotearoa was the place for his\n descendants and that Nukutawhiti was the right\n person to captain the waka hourua.",
      "voice": {
        "TRM": "S1/ID10 P4 TRM",
        "ENG": "S1/ID10 P4 ENG"
      }
    },
    {
      "id": 11,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka mahi tahi a Kupe rāua ko tētahi tohunga tārai\n waka, ko Tokaakuaku tōna ingoa. Ka mahi houtia\n tana waka, ka huaina, ko Ngātokimatawhaorua.",
      "english": "Together with another skilled waka builder,\n Tokaakuaku, Kupe re-adzed his waka\n and it was renamed Ngātokimatawhaorua.",
      "voice": {
        "TRM": "S1/ID11 P4 TRM",
        "ENG": "S1/ID11 P4 ENG"
      }
    },
    {
      "id": 12,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Me hua te waka nei, ko Ngātokimatawhaorua. He ingoa nō ngā\n toki e rua a Kupe rāua ko Tokaakuaku. Ko Ngā-paki-tua tā Kupe.\n Ko Tauira-ata tā Tokaakuaku.",
      "english": "The waka was named Ngātokimatawhaorua, to acknowledge\n the two toki that Tokaakuaku and Kupe used, Kupe’s adze,\n Ngā-paki-tua, and Tokaakuaku’s adze, Tauira-ata.",
      "voice": {
        "TRM": "S1/ID12 P4 TRM",
        "ENG": "S1/ID12 P4 ENG"
      }
    },

    // s1p5
    {
      "id": 13,
      "panel": 5,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka oti te waka nei, ka huihui katoa atu \nte iwi ki te one.",
      "english": "With the completion of the waka,\n Nukutawhiti and those others who were\n leaving gathered at the shoreline.",
      "voice": {
        "TRM": "S1/ID13 P5 TRM",
        "ENG": "S1/ID13 P5 ENG"
      }
    },
    {
      "id": 14,
      "panel": 5,
      "character": "Narrator",
      "type": "narration",
      "teReo":   "Ko ngā mea tamariki, pakeke hoki, ko te haere.\n Ko ngā mea kaumātua,\n ka mahue ki muri...",
      "english": "There were children and young adults,\n but for this journey,\n the older adults stayed behind...",
      "voice": {
        "TRM": "S1/ID14 P5 TRM",
        "ENG": "S1/ID14 P5 ENG"
      }
    },
    {
      "id": 15,
      "panel": 5,
      "character": "Nukutawhiti",
      "type": "thoughtBubble",
      "teReo": "E tū te huru mā, haramai e noho,\nE tū te huru pango, hanatu e haere",
      "english": "Let the white hair remain here,\nLet the black hair get up and go.",
      "voice": {
        "TRM": "S1/ID15 P5 TRM",
        "ENG": "S1/ID15 P5 ENG"
      }
    },

    // s1p6
    {
      "id": 16,
      "panel": 6,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka oti tēna,\n kua tika te iwi nei.\n He mahi anō tā tēnā, tā tēnā o rātou,\n i runga i te waka.",
      "english": "With that, the ship’s crew is ready,\n every person on board having\n an important role to fulfill\n during the trip.",
      "voice": {
        "TRM": "S1/ID16 P6 TRM",
        "ENG": "S1/ID16 P6 ENG"
      }
    },
    {
      "id": 17,
      "panel": 6,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Kua hou kuhu mai hoki tētahi ki te waka nei. He kuri nā Kupe. E kōingo ana tana ngākau ki te hoki mai ki tana whenua whānautanga mai,\n ki Aotearoa.",
      "english": "Ngātokimatawhaorua also has one unexpected traveller, hiding in the whare on the waka’s deck - one of Kupe’s dogs, yearning to return\n to Aotearoa where he was born.",
      "voice": {
        "TRM": "S1/ID17 P6 TRM",
        "ENG": "S1/ID17 P6 ENG"
      }
    }
];

const scene2_subtitles = [
    // ## s2p1 subs
    {
      "id": 18,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Pai te paki o te rā nei! Ki a tāua pea, he pai tēnei rā mō te haereere noa iho i te moana. Engari, he kaupapa tā Nukutawhiti.",
      "english": "What a beautiful day! Perfect to traverse the ocean you and I might think, however, Nukutawhiti begins his karakia...",
      "voice": {
        "TRM": "S2/ID18 P1 TRM",
        "ENG": "S2/ID18 P1 ENG"
      }
    },
    {
      "id": 19,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Tōna tikanga, ka karakia te tohunga kia marino ai te moana.",
      "english": "Most tohunga would do karakia to calm the sea, making the voyage less rough.",
      "voice": {
        "TRM": "S2/ID19 P1 TRM",
        "ENG": "S2/ID19 P1 ENG"
      }
    },
    {
      "id": 20,
      "panel": 1,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Kei a Kupe, he karakia whakaputa āwhā kē...",
      "english": "But Nukutawhiti was different, his karakia stirred the powerful and wild weather...",
      "voice": {
        "TRM": "S2/ID20 P1 TRM",
        "ENG": "S2/ID20 P1 ENG"
      }
    },

    // ## s2p2 subs

    // ## s2p2 karakia - TRM-only
    {
      "id": 21,
      "panel": 2,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": [
        "Papaa te whatitiri, uira kapakapa\n i runga o Taihoro-nuku-rangi.",
        "Hikihiki rangi ko Taane, i\n tuutakina ai ki te poouriori,\n Ki te poo tangotango.\n Kapiti rangi e tuu nei.",
        "Ko Taane i waahia mai te ara\n o Tawhiri-matea, Ki runga ki\n a Taihoro-nuku-rangi,",
        "Ki te whare o\n Maaui-tikitiki-a-Taranga,\n Te Moana-nui-a-Kiwa e takoto mai\n nei e.",
        "Tapuae nuku, tapuae rangi,\n he mumu, he aawhaa\n Te iho o te rangi e tuu iho nei.",
        "Ko te tohi o Tohi-nui-a-rangi,",
        "Tuutuu nui, tuutuu roa, tuutuu\n pookerekere, He hiikoinga ariki,\n i te tapu, i te whatu,\n i te ngana-a-hau-riri.",
        "Pakipaki te tai ki te papa rape nui\n o Taane. Pikipiki rangi, taakiri tuu,\n taakiri rangi ki te pae o Rehua.",
        "Tuputupu whenua ki te puu o\n Te Ika-a-Maaui. Tohi-ki-te-kura\n i tohia, ko Tawhaki,\n ko Tawhaki-nui-a-Hamanga.",
        "Haere te moana waiwai puta tara,\n puta tara Ki te whai ao,\n ki te ao maarama.",
      ],
      "english": "The thunder crashes,\n the lightning flashes\n over Taihoro-nuku-rangi.",
      "voice": {
        "TRM": "S2/ID21 P2 TRM",
        // "ENG": "S2/ID21 P2 TRM" // Play TRM-only for ENG?
      }
    },
    {
      "id": 22,
      "panel": 2,
      "character": "Kuri",
      "type": "thoughtBubble",
      "teReo": "E mara, he aha tēnā tū mahi? Titiro, marino ana te moana, paki ana te rā!",
      "english": [
        "Nukutawhiti is summoning Ngaru-nui. E mara, what are you doing?",
        "Look at the flat ocean and the sun, leave the weather as it is!"
      ],
      "voice": {
        "TRM": "S2/ID22 P2 TRM",
        "ENG": "S2/ID22 P2 ENG"
      }
    },
    {
      "id": 23,
      "panel": 2,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka hikohiko te uira, ka papā te whatitiri. Mea rawa atu, kua tū tētahi ngaru nui whakaharahara i raro i a Ngātokimatawhaorua. ",
      "english": "Lightning flashed and thunder boomed and soon, the ocean gathered underneath Ngātokimatawhaorua, forming a wave like none ever seen before.",
      "voice": {
        "TRM": "S2/ID23 P2 TRM",
        "ENG": "S2/ID23 P2 ENG"
      }
    },

    // ## s2p3
    {
      "id": 24,
      "panel": 3,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Hāunga ngā tamariki. Pekepeke haere kē ana ngā tamariki, nā te mea kua kite rātou e whā ngā taniwhā nei e pupuri ake ana i te waka i raro, kia tika ai tana rere. Anō nei, e rere ana i tētahi roto tino marino.",
      "english": "The children however, danced around the deck, for they had spotted the four taniwha holding the waka in place, keeping it as stable as if it had been placed gently in a calm, tranquil, smooth lake.",
      "voice": {
        "TRM": "S2/ID24 P3 TRM",
        "ENG": "S2/ID24 P3 ENG"
      }
    },
    {
      "id": 25,
      "panel": "3a",
      "character": "Child 1",
      "type": "speechBubble",
      "teReo": "Titiro ki te kaha o ngā taniwhā nei. I rongo au, tō ratou kaha e rite ana ki te kotahi rau mangō taniwhā.",
      "english": "Look at how strong these taniwha are, I heard that they have the strength of 100 mangō taniwha - the great white shark.",
      "voice": {
        "TRM": "S2/ID25 P3 TRM",
        "ENG": "S2/ID25 P3 ENG"
      }
    },
    {
      "id": 26,
      "panel": "3a",
      "character": "Child 2",
      "type": "speechBubble",
      "teReo": "E hē ana koe. Kotahi mano kē, tahi anō ka tika!",
      "english": "E mara, kei te hē tērā - everyone knows it’s 1000 great whites!",
      "voice": {
        "TRM": "S2/ID26 P3 TRM",
        "ENG": "S2/ID26 P3 ENG"
      }
    },    
    {
      "id": 27,
      "panel": "3a",
      "character": "Kuri",
      "type": "speechBubble",
      "teReo": "Kotahi rau rānei, kotahi mano rānei. Hoi taku whakaaro, kia pai taku āhua ki aua taniwhā mō te roanga atu o tēnei haerenga...",
      "english": "Whether 100 or 1000, all I know is I’m staying\n on their good side for this journey...",
      "voice": {
        "TRM": "S2/ID27 P3 TRM",
        "ENG": "S2/ID27 P3 ENG"
      }
    },

    // ## s2p4
    {
      "id": 28,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ko te mahi a Nukutawhiti mā i runga i te waka, he mātakitaki i ngā tohu i mōhio ai rātou ki te ara tika.",
      "english": "Nukutawhiti and the other navigators on board were busy studying all of the evidence that told them the right pathway to take.",
      "voice": {
        "TRM": "S2/ID28 P4 TRM",
        "ENG": "S2/ID28 P4 ENG"
      }
    },
    {
      "id": 29,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "E tino whakanuia ana ēnei tohunga mō tō rātou mātauranga whakahirahira. Ko ngā mātauranga hoki ēnei i oti ai tēnei tū haere i te hia rau waka hourua i mua.",
      "english": "Our Māori navigators and our many cousins around Te Moana-nui-a-Kiwa are celebrated for their immense navigational knowledge, and we all know that hundreds of waka hourua completed the journey, each with their own navigational techniques and styles.",
      "voice": {
        "TRM": "S2/ID29 P4 TRM",
        "ENG": "S2/ID29 P4 ENG"
      }
    },
    {
      "id": 30,
      "panel": 4,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Hoi, kātahi ka tū ohorere te waka nei. Horekau he neke ki mua, ki muri, ki ngā tahataha rānei.",
      "english": "Suddenly, however, there was a crash as one of Ngātokimatawhaorua’s hulls struck something beneath the surface.",
      "voice": {
        "TRM": "S2/ID30 P4 TRM",
        "ENG": "S2/ID30 P4 ENG"
      }
    },
    {
      "id": 31,
      "panel": 4,
      "character": "Kuri",
      "type": "speechBubble",
      "teReo": "He aha te raru? He aha i tū noa ai?",
      "english": "What’s that,\n why have we stopped moving! Help!",
      "voice": {
        "TRM": "S2/ID31 P4 TRM",
        "ENG": "S2/ID31 P4 ENG"
      }
    },
    {
      "id": 32,
      "panel": 4,
      "character": "Puhimoanaariki",
      "type": "speechBubble",
      "teReo": "Nukutawhiti, kua paoro tātou ki tētahi toka i roto i te moana. He papa kōhatu, ko tōna ingoa, ko Kahukura.",
      "english": "E Nukutawhiti, we have struck a rock submerged underneath the water, part of a reef - the giant nets of Kahukura.",
      "voice": {
        "TRM": "S2/ID32 P4 TRM",
        "ENG": "S2/ID32 P4 ENG"
      }
    },

    // ## s2p5
    {
      "id": 33,
      "panel": 5,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Tū tonu atu a Nukutawhiti ki te karakia, he karakia poro i te kaharunga, i te kahararo hoki o ngā kupenga a Kahukura.",
      "english": "With urgency and haste, Nukutawhiti leaps back onto Ngātokimatawhaorua, and began his karakia, trying to cut the kaharunga and the kahararo of Kahuhura’s net. ",
      "voice": {
        "TRM": "S2/ID33 P5 TRM",
        "ENG": "S2/ID33 P5 ENG"
      }
    },
    {
      "id": 34,
      "panel": 5,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Nā te pēneitanga, kātahi anō ka motu ngā taura - ka puta te waka, ka tatū te ngākau o te iwi.",
      "english": "Suddenly - the waka shot forward, and a cheer erupted from the people on board.",
      "voice": {
        "TRM": "S2/ID34 P5 TRM",
        "ENG": "S2/ID34 P5 ENG"
      }
    },
    {
      "id": 35,
      "panel": 5,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Puhimoana-ariki, nāu tātou i ora ai i te paremotanga ki te moana.",
      "english": "If it was not for you, we would not have known what was happening below the waka, what karakia to use, and we would almost certainly have been lost to the ocean.",
      "voice": {
        "TRM": "S2/ID35 P5 TRM",
        "ENG": "S2/ID35 P5 ENG"
      }
    },
    {
      "id": 36,
      "panel": 5,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Tō mōhio ki te tirotiro haere i ngā ngaru, kei hea ngā raru mō tātou.",
      "english": "The way you wander up and down the waves looking for dangers has saved us from Ngātokimatawhaorua.",
      "voice": {
        "TRM": "S2/ID36 P5 TRM",
        "ENG": "S2/ID36 P5 ENG"
      }
    },
    {
      "id": 37,
      "panel": 5,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Nō reira, ko te mea tika anō, me hoatu ki a koe te ingoa nei.Tēnā koe e Puhi-te-aewa.",
      "english": "It is only right that your name should reflect this great deed, e Puhi-te-aewa (Puhi-the-wanderer).",
      "voice": {
        "TRM": "S2/ID37 P5 TRM",
        "ENG": "S2/ID37 P5 ENG"
      }
    },
    {
      "id": 38,
      "panel": 5,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka ora rātou i te paremotanga, ka haere tonu te waka nei, ahu tonu mai ki Aotearoa, ki Te Puna i te Ao Mārama, ki tō rātou kāinga hou.",
      "english": "With that crisis averted, the waka continued its journey onwards towards Aotearoa, to Te Puna i te Ao Marama, to their new home.",
      "voice": {
        "TRM": "S2/ID38 P5 TRM",
        "ENG": "S2/ID38 P5 ENG"
      }
    },

    // ## s2p6
    {
      "id": 39,
      "panel": 6,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka tito waiata ngā tamariki, hei whakamaharatanga ki te ngaru\n kaimau i a rātou ki tō rātou kāinga hou.",
      "english": "As the waka sped along, the children composed a waiata, to\n acknowledge the wave that was carrying them to their new home.",
      "voice": {
        "TRM": "S2/ID39 P6 TRM",
        "ENG": "S2/ID39 P6 ENG"
      }
    },
    {
      "id": 40,
      "panel": 6,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Ngaru nui, ngaru roa, ngaru pae-whenua. Te ngaru i mauria mai ai a Ngaa-toki-maataa-hou-rua",
      "english": "Great wave, long wave, wave like a mountain range. The wave that brought hither Ngaa-toki-maataa-hou-rua",
      "voice": {
        "TRM": "S2/ID40_P6_Waiata_TRM",
        "ENG": "S2/ID40_P6_Waiata_ENG"
      }
    },
];

const scene3a_subtitles = [
    // ## S3aP1 subs
    {
      "id": 41,
      "panel": 1,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Titiro - Te Puna i Te Ao Marama. Te Hokianga nui a Kupe!",
      "english": "Look - Te Puna i Te Ao Marama. Te Hokianga nui a Kupe!",
      "voice": {
        "TRM": "S3/ID41 P1 TRM",
        "ENG": "S3/ID41 P1 ENG",
      }
    },
    // ## S3aP2 subs
    {
      "id": 42,
      "panel": 2,
      "character": "Narrator",
      "type": "narration",
      "teReo": "Ka tata haere a Ngatokimatawhaorua ki te whenua, ka hoki ngā whakaaro o Nukutawhiti ki ngā kupu a Kupe.",
      "english": "As Ngatokimatawhaorua gradually\n got closer to land, Nukutawhiti\n quietly reflects on the words of\n Kupe.",
      "voice": {
        "TRM": "S3/ID42 P2 TRM",
        "ENG": "S3/ID42 P2 ENG",
      }
    },
    {
      "id": 43,
      "panel": 2,
      "character": "Nukutawhiti",
      "type": "thoughtBubble",
      "teReo": "Hei konei rā, e Te Puna i te Ao\n Mārama, Ka hoki nei tēnei, e kore\n e hoki anga nui mai",
      "english": "Goodbye, Spring of the World of Light, This one is going home and will not return this way again",
      "voice": {
        "TRM": "S3/ID43 P2 TRM",
        "ENG": "S3/ID43 P2 ENG",
      }
    },
    // ## S3aP3 subs
    {
      "id": 44,
      "panel": 3,
      "character": "Narrator",
      "type": "narration",
      "teReo": "I te tatanga atu ki te whenua, ka keri ngā ngaru. Kātahi a Nukutawhiti ka tuku i tana karakia whakarangimārie i te moana, ka tuku atu hoki i tana amokura, hei takoha ki ngā atua.",
      "english": "Suddenly, as Ngatokimatawhaorua\n approached land, the ocean grew\n rough. Nukutawhiti recited karakia\n to calm the waters, and casts forth\n his amokura as a gift to the atua.",
      "voice": {
        "TRM": "S3/ID44 P3 TRM",
        "ENG": "S3/ID44 P3 ENG",
      }
    },
    {
      "id": 45,
      "panel": 3,
      "character": "Nukutawhiti",
      "type": "speechBubble",
      "teReo": "Ko te mauri he mea huna ki te\n moana.",
      "english": "The mauri [life principle or\n living source] is hidden in the sea",
      "voice": {
        "TRM": "S3/ID45 P3 TRM",
      }
    },
    {}, // blank because 46 doesnt exist
];

const scene3b_subtitles = [
  // ## S3bP1 subs
  {
    "id": 47,
    "panel": 1,
    "character": "Narrator",
    "type": "narration",
    "teReo": ["I mua i te hekenga atu o tana iwi ki\n uta, ka hūtia ake e Nukutawhiti e\n waru ngā tupu rimu.", "E rua tupu ka whakahokia ki Hawaiki\n me ngā taniwhā e rua nei, a Puhi-te-\naewa rāua ko Rangi-uruhinga."],
    "english": ["Before all of his people went ashore, Nukutawhiti plucked eight rimu shoots -", "two of which he sent back to Hawaiki with two of the taniwha – Puhi-te-aewa and Rangi-uruhinga."],
    "voice": {
      "TRM": "S3/ID47 P1 TRM",
      "ENG": "S3/ID47 P1 ENG",
    }
  },
  // ## S3bP2 subs
  {
    "id": 48,
    "panel": 2,
    "character": "Narrator",
    "type": "narration",
    "teReo": "He karere tēnei ki a Kupe kua tae ora mai a Nukutawhiti me te iwi i tere mai nei mā runga i a Ngātokimatawhaorua.",
    "english": "This was a message for Kupe to inform him that Nukutawhiti and the people of Ngātokimatawhaorua had arrived safely.",
    "voice": {
      "TRM": "S3/ID48 P2 TRM",
      "ENG": "S3/ID48 P2 ENG",
    }
  },
  // ## S3bP3 subs
  {
    "id": 49,
    "panel": 3,
    "character": "Narrator",
    "type": "narration",
    "teReo": ["Ko ngā taniwhā nei, a Āraiteuru rāua ko Niniwa (ko Niua nei hoki tētahi o ōna nei ingoa),", "ka noho mai hei kaitiaki i te wahapū o Hokianga. He maha ngā āhua o aua taniwhā nei, he toka, he ngaru, he aha atu."],
    "english": ["The taniwha Āraiteuru and Niniwa (also known as Niua) became guardians of the entrance to the Hokianga harbour,", "where they continue to materialise in many forms, including rocks and waves."],
    "voice": {
      "TRM": "S3/ID49 P3 TRM",
      "ENG": "S3/ID49 P3 ENG",
    }
  },
];

const subtitles = scene1_subtitles
  .concat(scene2_subtitles)
  .concat(scene3a_subtitles)
  .concat(scene3b_subtitles);

export default subtitles;
