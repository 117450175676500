import { registerWalkHero } from '../../../animation/animationEffect';
import { registerSetSubtitleWithFade } from '../../../subtitles/subtitleEffects';
import { subtitleLength } from '../../../subtitles/subtitleConstants';
import * as sprites from '../../../animation/sprites';
import { NUKU_SCALE_NORMAL } from '../../../animation/animationSizes';

registerSetSubtitleWithFade();
registerWalkHero();

const nukuStartPosition = 300; //px is default for gsap
const hero = document.getElementById('hero');

const setupTl = () => gsap.timeline({ paused: false, smoothChildTiming: true })
    .call(console.log, ["setup"])
    .set(hero, { opacity:1 })
    .set('#container', { scale: 1 }) //for some reason a set here does not work at all.
    .set('#hero', { scale: NUKU_SCALE_NORMAL })
    .set('.subtitle', { opacity: 0 })
    .set('#hero', { x: '-300px' })
    .call(() => {
        sprites.waterfallSprite.play();
        sprites.putataraSprite.play();
        console.log('sprites playing!')
    });


const introTl = () => gsap.timeline({ id: "introTl", paused: false, smoothChildTiming: true })
    .call(console.log, ["intro"])
    //scroll hint
    // .to('.scroll-prompt', { ma`r`ginBottom: '30', yoyo:'true', repeat:-1 })
    .to('.scroll-prompt', { marginBottom: '-130px', duration:4})


    .to('#container', { scale: 1 }, '<')
    //.walkHero('#hero', { duration: 30, distance: 300, freezeContainer: true, parallax: false }, 0)
    .set('.subtitle', { opacity: 0 })
    // Snap plants into position
    .from('#plants', { y: 150, x: -80, duration: 4, ease: 'back' }, "-=2") //2 secs from end.

const panel1Tl = () => gsap.timeline({ id: "panel1", paused: false, smoothChildTiming: true })
    .call(console.log, ["p1"])
    .walkHero('#hero', { duration: 25, distance: 600, freezeContainer: true, parallax: 0.5, skip_parallax_fg: true }, 0) // 40px/s

    

    .walkHero('#hero', { distance: 250, duration: 49, freezeContainer: false, parallax: true }) // 6.25px/s. was 60 delay. Give walk a small delayed start.
    .add("heroWalkStart1", '<') //label for walk timings

    // narration 1 appears - 
    .setSubtitleWithFade('#top-narration-slot-1', { subTitle: 1 - 1, x: 100, width: 400, height: 120 }, "heroWalkStart1") //start 4 seconds after audio comes in

    //narrations to come before  and speech bubble
    // narration 2 appears - 
    .setSubtitleWithFade('#top-narration-slot-2', { subTitle: 2 - 1, x: 499, width: 492, height: 120 }, `heroWalkStart1+=${subtitleLength}`) //start

    // narration text 3 - He seeks adventure
    .setSubtitleWithFade('#bottom-narration-slot-1', { subTitle: 3 - 1, left: 550, width: 450 }, `heroWalkStart1+=${subtitleLength*2}`)

    .call(() => { console.log('DBG p1 finished'); }, [], '>')
    ;

export { introTl, panel1Tl, setupTl }